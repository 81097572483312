import React, { Fragment, ReactNode } from 'react';
import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbItem,
} from '@fluentui/react-components';

import useClasses from './styles';

interface IBreadcrumbItem {
  label: string | ReactNode;
  path: string;
}

interface BreadcrumbsProps {
  items: IBreadcrumbItem[];
}

function Breadcrumbs(props: BreadcrumbsProps) {
  const { items } = props;
  const styles = useClasses();

  return (
    <Breadcrumb aria-label="Breadcrumb" className={styles.wrapper}>
      {
        items.map((item, index, array) => (
          <Fragment key={item.path}>
            <BreadcrumbItem>
              <BreadcrumbButton href={item.path} className={styles.label}>{item.label}</BreadcrumbButton>
            </BreadcrumbItem>
            {array.length - 1 !== index && <span className={styles.dot}>•</span>}
          </Fragment>
        ))
      }

    </Breadcrumb>
  );
}

export default Breadcrumbs;