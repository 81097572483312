export interface IData {
  id: string;
  title: string;
  step: string,
  description: string,
}

export const data: IData[] = [
  {
    id: '1',
    step: 'Step 1',
    title: 'Create the Blog Content Structure',
    description: 'We start by creating the structure of the articles we intend to write, finding the best keywords for the headings and the body. The blog writing involves selecting a topic, creating compelling subheadings, and target keywords, and determining what images we would add and where. We constantly refer to the discussion we had when you contacted us.',
  },
  {
    id: '2',
    step: 'Step 2',
    title: 'Research the Blog Topic',
    description: 'Our writers then research data available in open sources based on the set topic. We ensure that the information we use in crafting blog posts is obtained from accurate, verifiable, and reputable sources. In their blog writing research, the authors use diverse sources related to the topic to provide a wealth of information to their target audience.',
  },
  {
    id: '3',
    step: 'Step 3',
    title: 'Develop a Draft',
    description: 'Our blog writing team produces blog posts based on the content structure we provided and the research they conducted. They are keen to use the required keywords and maintain your brand voice throughout the draft development process. The draft will also meet the set word count goal in your preferred language.',
  },
  {
    id: '4',
    step: 'Step 4',
    title: 'Finalization and Polishing the Blog Post',
    description: 'Once our blog writers complete the draft, another team looks at the content to ensure it meets the requirements outlined in the technical task and enriched with the details from our discussion.  We also polish the blog post to get rid of errors and ensure that it has the right tone.',
  },
];