import { useClassNames } from '../../../styles/global.fluentui.styles';
import { Col, Row } from '../../../components/shared/Grid';
import BlogWritingCarousel from './ BlogWritingCarousel';
import { data } from './data';

import useClasses from './styles';

export default function BlogWritingServicesWorkSection() {
  const globalStyles = useClassNames();
  const styles = useClasses();

  return (
    <section className={globalStyles.wrapperSection}>
      <div className="container">
        <Row>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 12 }}
            offset={{
              mobile: 0,
              tablet: 0,
              desktop: 2,
              large: 2,
              default: 0,
            }}
          >
            <div className={globalStyles.wrapperHeaderSection}>
              <h1 className={globalStyles.mainHeader}>How Blog Writing Services Work</h1>
            </div>
          </Col>
        </Row>
        <Row gap={36} className={styles.wrapperCards}>
          {
            data.map(data => (
              <Col span={6} key={data.id}>
                <div className={styles.wrapperColumn}>
                  <span className={styles.step}>{data.step}</span>
                  <h3>{data.title}</h3>
                  <p>
                    {data.description}
                  </p>
                </div>
              </Col>
            ))
          }
        </Row>

        <BlogWritingCarousel />
      </div>
    </section>
  );
}