import { Image, makeStyles, tokens } from '@fluentui/react-components';
import { Link } from 'react-router-dom';

import img1 from '../../../assets/images/home/newsMonitoring/image1.webp';
import img2 from '../../../assets/images/home/newsMonitoring/image2.webp';
import { Col, Row } from '../../../components/shared/Grid';
import { useClassNames } from '../../../styles/global.fluentui.styles';

const useClasses = makeStyles({
  wrapper: {
    backgroundColor: tokens.colorNeutralBackground4,
    padding: '4rem 0px',
    '@media (max-width: 1439px)': {
      padding: '3rem 0px',
    },
    '@media (max-width: 767px)': {
      padding: '2rem 0px',
    },
  },
  wrapperColumn: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: tokens.colorNeutralBackground1,
    borderRadius: tokens.borderRadiusLarge,
    position: 'relative',
    '&:hover .overlayClass': {
      opacity: 1,
    },
  },
  image: {
    width: '100%',
    minHeight: '23.6rem',
    height: '23.6rem',
    borderTopRightRadius: tokens.borderRadiusLarge,
    borderTopLeftRadius: tokens.borderRadiusLarge,
    '@media (max-width: 767px)': {
      height: '17rem',
      minHeight: '17rem',
    },
  },
  wrapperText: {
    padding: '2.5rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1919px)': {
      padding: '2rem 2rem',
    },
    '@media (max-width: 1439px)': {
      padding: '2rem 1.5rem',
    },
    '@media (max-width: 767px)': {
      padding: '1rem 1rem 1.38rem 1rem',
    },
  },

  columnHeader: {
    marginBottom: '0.62rem',
    fontWeight: tokens.fontWeightSemibold,
    fontSize: tokens.fontSizeBase500,
    lineHeight: tokens.lineHeightBase500,
    '@media (max-width: 1439px)': {
      fontSize: tokens.fontSizeBase400,
      lineHeight: tokens.lineHeightBase400,
    },
    '@media (max-width: 767px)': {
      fontSize: tokens.fontSizeBase300,
      lineHeight: tokens.lineHeightBase300,
    },
  },
  columnDescription: {
    marginBottom: '2.3rem',
    color: tokens.colorNeutralForeground2,
    fontSize: tokens.lineHeightBase400,
    fontWeight: tokens.fontWeightRegular,
    lineHeight: tokens.lineHeightBase400,
    '@media (max-width: 1439px)': {
      marginBottom: '1.38rem',
      fontSize: tokens.fontSizeBase300,
      lineHeight: tokens.lineHeightBase300,
    },
  },
  link: {
    textDecoration: 'underline',
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase400,
    color: tokens.colorNeutralForeground1,
    textUnderlineOffset: '4px',
  },

  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#00000080',
    borderRadius: tokens.borderRadiusLarge,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop: '11.8rem',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    color: '#fff',
    fontSize: tokens.fontSizeBase600,
    fontWeight: tokens.fontWeightRegular,
    lineHeight: tokens.lineHeightBase600,
    zIndex: 1,
    '@media (max-width: 767px)': {
      paddingTop: '8.5rem',
    },
  },
});

export default function NewsMonitoringSection() {
  const fluentStyles = useClasses();
  const globalStyles = useClassNames();

  return (
    <section className={fluentStyles.wrapper}>
      <div className="container">
        <Row>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 12 }}
            offset={{
              mobile: 0,
              tablet: 0,
              desktop: 2,
              large: 2,
              default: 0,
            }}
          >
            <div className={globalStyles.wrapperHeaderSection}>
              <h1 className={globalStyles.mainHeader}>
                News API and Media Monitoring Tool
              </h1>
              <h2 className={globalStyles.mainDescription}>
                Are you seeking a free API news solution for world trending news
                headlines? You can automate the publishing of trending news feeds
                using our news API free software. Our AI Media Monitoring Tool
                cherry-picks potent trending news topics. The trends are then
                categorized by location and article type.
              </h2>
            </div>
          </Col>
        </Row>
        <Row
          gap={{ mobile: 0, tablet: 32, desktop: 32, large: 32, default: 32 }}
          rowGap={{
            mobile: 32,
            default: 0,
          }}
        >
          <Col
            span={{ mobile: 12, tablet: 6, desktop: 6, large: 6, default: 6 }}
            className={fluentStyles.wrapperColumn}
          >
            <div className={`${fluentStyles.overlay} overlayClass`}>
              Coming soon...
            </div>
            <Image className={fluentStyles.image} src={img1} />
            <div className={fluentStyles.wrapperText}>
              <h4 className={fluentStyles.columnHeader}>AI News Researcher</h4>
              <span className={fluentStyles.columnDescription}>
                Simplify news research with our AI Media Monitoring Tool. Spot
                the most potent and impactful news from any location and
                language across the web.
              </span>
              <div>
                <Link className={fluentStyles.link} to="/">
                  Learn More
                </Link>
              </div>
            </div>
          </Col>
          <Col
            span={{ mobile: 12, tablet: 6, desktop: 6, large: 6, default: 6 }}
            className={fluentStyles.wrapperColumn}
          >
            <div className={`${fluentStyles.overlay} overlayClass`}>
              Coming soon...
            </div>
            <Image className={fluentStyles.image} src={img2} />
            <div className={fluentStyles.wrapperText}>
              <h4 className={fluentStyles.columnHeader}>News API</h4>
              <span className={fluentStyles.columnDescription}>
                Connect to the free API for Google News or a custom news source
                to get live updates on the latest global events or topics of
                your interest.
              </span>
              <div>
                <Link className={fluentStyles.link} to="/">
                  Learn More
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}
