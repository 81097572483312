import React from 'react';
import {
  CarouselCard,
  Image,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components';
import { Link } from 'react-router-dom';

import { ContentServiceCard } from '../../data';

const useClasses = makeStyles({
  wireframe: {
    display: 'flex',
    flexDirection: 'column',
    height: '22rem',
    backgroundColor: tokens.colorNeutralBackground1,
    position: 'relative',
    borderRadius: '5px',
    '& img': {
      height: '11.5rem',
      borderTopRightRadius: '5px',
      borderTopLeftRadius: '5px',
    },
    '@media (max-width: 1439px)': {
      height: '21rem',
    },
  },
  wireframeSize: {
    minWidth: '100px',
    maxWidth: '24rem',
  },
  headerText: {
    fontWeight: tokens.fontWeightSemibold,
    fontSize: tokens.fontSizeBase500,
    lineHeight: tokens.lineHeightBase500,
    marginBottom: '0.63rem',
    '@media (max-width: 1439px)': {
      fontSize: tokens.fontSizeBase400,
      lineHeight: tokens.lineHeightBase400,
    },
  },
  descriptionText: {
    fontWeight: tokens.fontWeightRegular,
    fontSize: tokens.fontSizeBase400,
    lineHeight: tokens.lineHeightBase400,
    marginBottom: '1rem',
    display: 'block',
    '@media (max-width: 1439px)': {
      fontSize: tokens.fontSizeBase300,
      lineHeight: tokens.lineHeightBase300,
    },
  },
  link: {
    textDecoration: 'underline',
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase400,
    color: tokens.colorNeutralForeground1,
    textUnderlineOffset: '4px',
    '@media (max-width: 1439px)': {
      fontSize: tokens.fontSizeBase300,
      lineHeight: tokens.lineHeightBase300,
    },
  },
  wrapperCardContent: {
    padding: '0.5rem 1rem',
  },
  image: {
    filter: 'brightness(0.8)',
  },
});

type CarouselCardProps = {
  content: ContentServiceCard;
  index: number;
  contentServices: ContentServiceCard[];
  className?: string;
};

export default function ContentCarouselCard(props: CarouselCardProps) {
  const { content, index, contentServices } = props;
  const fluentStyles = useClasses();

  return (
    <CarouselCard autoSize aria-label={`${index} of ${contentServices.length}`}>
      <div
        className={mergeClasses(
          fluentStyles.wireframe,
          fluentStyles.wireframeSize,
        )}
      >
        <Image className={fluentStyles.image} fit="cover" src={content.imageSrc} role="presentation" />
        <div className={fluentStyles.wrapperCardContent}>
          <h1 className={fluentStyles.headerText}>{content.title}</h1>
          <span className={fluentStyles.descriptionText}>
            {content.description}
          </span>
          <div>
            <Link className={fluentStyles.link} to={content.link}>
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </CarouselCard>
  );
}
