import MainBanner from './MainBanner';
import NicheContentWritingSection from './NicheContentWritingSection';
import ContentIsKingSection from './ContentIsKingSection';
import ContactUsInfoSection from './ContactUsInfoSection';
import TagsSection from '../MainPage/TagsSection';
import WhyUsSection from './WhyUsSection';
import ContentQuestionSection from './ContentQuestionSection';
import ContentStrategyServicesSection from './ContentStrategyServicesSection';
import PriceInfoSection from './PriceInfoSection';

export default function ContentWritingServicesPage() {
  return (
    <>
      <MainBanner />
      <NicheContentWritingSection />
      <ContentIsKingSection />
      <ContentStrategyServicesSection />
      <PriceInfoSection />
      <WhyUsSection />
      <ContactUsInfoSection />
      <ContentQuestionSection />
      <TagsSection />
    </>
  );
}