export interface QuestionModel {
  id: string;
  title: string;
  description: string;
}

const questions: QuestionModel[] = [
  {
    id: '1',
    title: 'Why outsource content writing to Contenton? ',
    description: 'At Contenton, we talk, speak, and walk quality content. If you ever desire to outsource content writing, we guarantee timely delivery and unlimited revisions.  ',
  },
  {
    id: '2',
    title: 'What is SEO content writing? ',
    description: 'SEO content writing involves using keywords to optimize your web content so it ranks higher on search engines. The written content must be relevant, convincing, and informative to drive traffic.',
  },
  {
    id: '3',
    title: 'What is a niche in content writing?',
    description: 'A niche involves specializing in a specific area of content writing. A writer in niche content writing has amassed in-depth knowledge and skills in a particular line of writing.',
  },
  {
    id: '4',
    title: 'What is b2b saas content writing?',
    description: 'B2B SaaS content writing entails specializing in the SaaS writing niche. The main areas of SaaS content writing include article guides or user manuals that explain how users should interact with the software, what are the benefits of your software as a service type of product. Basically, presenting technical stuff in layman\'s or industry expert language.',
  },
  {
    id: '5',
    title: 'How to write a product description?',
    description: 'Product description writing is popular in e-commerce content writing. The content should capture the main features of the products and associated benefits and main specification. It must be persuasive and have a call to action.',
  },
  {
    id: '6',
    title: 'How to write blog posts faster?',
    description: 'To write your blog post faster, start with a detailed outline for the written content. For faster blog posts writing, consider breaking down the contents into smaller and manageable chunks. You can always outsource your blog to a content writing studio, like Contenton.',
  },
  {
    id: '7',
    title: 'How many blog posts to get traffic?',
    description: 'If you intend to grow traffic using SEO content writing, there is no limit to the blog posts you can post each week. Aim to post at least 2-3 times in blog posts writing each week.',
  },
  {
    id: '8',
    title: 'Why content marketing is important?',
    description: 'Content marketing is a crucial part of content planning. It helps create brand awareness and increase website conversions. An effective content marketing strategy assists in establishing a trustworthy and credible audience.',
  },
  {
    id: '9',
    title: 'What is B2B content marketing?',
    description: 'B2B content marketing involves developing and distributing content to businesses to enhance brand awareness. An effective B2B content strategy will increase traffic to your website and improve sales.',
  },
];

export default questions;