import { makeStyles, tokens } from '@fluentui/react-components';

import mainBanner from '../../../assets/images/contentWritingServices/mainBanner.webp';

const useClasses = makeStyles({
  backgroundImage: {
    width: '100%',
    padding: '2.06rem 0px 7.88rem 0px',
    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url(${mainBanner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'lightgray',
    '@media (max-width: 1439px)': {
      padding: '3rem 0px 5.69rem 0px',
    },
    '@media (max-width: 767px)': {
      padding: '2rem 0px 3.5rem 0px',

    },
  },
  fullHeight: {
    height: '100%',
  },
  wrapperColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '4.44rem',
    '@media (max-width: 1439px)': {
      paddingTop: '1.19rem',
    },
    '@media (max-width: 767px)': {
      paddingTop: '2rem',
    },
  },
  mainText: {
    textAlign: 'center',
    fontSize: tokens.fontSizeHero1000,
    lineHeight: tokens.lineHeightHero1000,
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorNeutralForegroundStaticInverted,
    '@media (max-width: 1439px)': {
      marginBottom: '0.5rem',
    },
    '@media (max-width: 767px)': {
      fontSize: tokens.fontSizeHero800,
      lineHeight: tokens.lineHeightHero800,
    },
  },
  subText: {
    fontSize: tokens.fontSizeBase500,
    lineHeight: tokens.lineHeightBase500,
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorNeutralForegroundStaticInverted,
    textAlign: 'center',
    marginBottom: '2rem',
    '@media (max-width: 767px)': {
      fontSize: tokens.fontSizeBase400,
      lineHeight: tokens.lineHeightBase400,
    },
  },
  buttonWrapper: {
    padding: '0.5rem 3.47rem',
    fontSize: tokens.fontSizeBase400,
    lineHeight: tokens.lineHeightBase400,
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorNeutralForegroundInverted,
    backgroundColor: tokens.colorNeutralForeground1,
    borderRadius: tokens.borderRadiusCircular,
    '@media (max-width: 767px)': {
      padding: '0.31rem 3.78rem',
      fontSize: tokens.fontSizeBase300,
      lineHeight: tokens.lineHeightBase300,
    },
  },
});

export default useClasses;