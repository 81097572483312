import Icon from '../../../components/shared/Icon';
import { useClassNames } from '../../../styles/global.fluentui.styles';
import { featuresData } from './data';
import useClasses from './styles';
import { Col, Row } from '../../../components/shared/Grid';

export default function WhyUsSection() {
  const globalStyles = useClassNames();
  const styles = useClasses();

  const firstThreeFeatures = featuresData.slice(0, 3);
  const lastTwoFeatures = featuresData.slice(3, 5);

  return (
    <section className={globalStyles.wrapperSection}>
      <div className="container">
        <div className={globalStyles.wrapperHeaderSection}>
          <h1 className={globalStyles.mainHeader}>Why us?</h1>
        </div>

        <Row className={styles.wrapperFeatures}>
          {firstThreeFeatures.map(feature => (
            <Col span={{ mobile: 12, tablet: 4, desktop: 4, large: 4, default: 4 }} className={styles.wrapperCard}
                 key={feature.id}>
              <div>
                <Icon iconName={feature.iconClassName} pathCount={feature.pathCount} />
                <span className={styles.infoText}>{feature.title}</span>
              </div>
            </Col>))
          }
          {lastTwoFeatures.map(feature => (
            <Col
              span={{ mobile: 12, tablet: 6, desktop: 6, large: 6, default: 6 }}
              className={styles.wrapperCard} key={feature.id}>
              <div>
                <Icon iconName={feature.iconClassName} pathCount={feature.pathCount} />
                <span className={styles.infoText}>{feature.title}</span>
              </div>
            </Col>))
          }
        </Row>

      </div>
    </section>
  );
}