export interface QuestionModel {
  id: string;
  title: string;
  description: string;
}

export const questions: QuestionModel[] = [
  {
    id: '1',
    title: 'What is Contenton?',
    description:
      'Contenton is an all-round and best content writing services platform. Contenton provides original human writing services, auto-blogging and content automation with AI-generated content, a news monitoring tool and clasterizing news API powered by AI.',
  },
  {
    id: '2',
    title: 'How to outsource Content Writing services?',
    description:
      'Contenton provides original human writing services, auto-blogging and content automation with AI-generated content, a news monitoring tool and clasterizing news API powered by AI.',
  },
  {
    id: '3',
    title: 'How to write a product description?',
    description:
      'Contenton is an all-round and best content writing services platform.',
  },
  {
    id: '4',
    title: 'What is SEO content?',
    description: 'Contenton provides original human writing services',
  },
  {
    id: '5',
    title: 'How to create a blog on WordPress?',
    description:
      'Contenton is an all-round and best content writing services platform. Contenton provides original human writing services, auto-blogging and content automation with AI-generated content, a news monitoring tool and clasterizing news API powered by AI.',
  },
  {
    id: '6',
    title: 'What is content automation?',
    description: 'AI-generated content',
  },
  {
    id: '7',
    title: 'What is Content Marketing?',
    description:
      'Contenton is an all-round and best content writing services platform.',
  },
  {
    id: '8',
    title: 'What is News API?',
    description:
      'Contenton is an all-round and best content writing services platform.',
  },
  {
    id: '9',
    title: 'Who needs media & news monitoring tool?',
    description: 'Contenton provides original human writing services',
  },
];
