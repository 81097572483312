import { useClassNames } from '../../../styles/global.fluentui.styles';
import { Col, Row } from '../../../components/shared/Grid';
import { data } from './data';
import WritingServiceCarousel from './ WritingServiceCarousel';

import useClasses from './styles';

export default function WritingServiceStepsSection() {
  const globalStyles = useClassNames();
  const styles = useClasses();

  return (
    <section className={globalStyles.wrapperSection}>
      <div className="container">
        <Row>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 12 }}
            offset={{
              mobile: 0,
              tablet: 0,
              desktop: 2,
              large: 2,
              default: 0,
            }}
          >
            <div className={globalStyles.wrapperHeaderSection}>
              <h1 className={globalStyles.mainHeader}>How We Provide the Best Blog Writing Services</h1>
              <h2 className={globalStyles.mainDescription}>The texts for your blog undergo various stages before we
                deliver
                the final piece for ready publishing to
                you. The standardized production and quality control process ensures that every piece we create
                satisfies
                the customer’s needs and expectations. Here is an overview of the process.</h2>
            </div>
          </Col>
        </Row>
        <Row gap={36} className={styles.wrapperCards}>
          {data.map(data => <Col span={6} key={data.id}>
              <div className={styles.wrapperColumn}>
                <span className={styles.step}>{data.step}</span>
                <h3>{data.title}</h3>
                <p>
                  {data.description}
                </p>
              </div>
            </Col>,
          )}
        </Row>

        <WritingServiceCarousel data={data} />
      </div>
    </section>
  );
}