import { makeStyles, tokens } from '@fluentui/react-components';

const useClasses = makeStyles({
  tableWrapper: {
    backgroundColor: tokens.colorNeutralBackground1,
    padding: '2rem',
    borderRadius: '8px',
    marginBottom: '2rem',
    '@media (max-width: 767px)': {
      padding: '1rem',
    },
    '& table': {
      border: `1px solid ${tokens.colorNeutralStroke1}`,
      borderSpacing: 0,
    },
    '& td': {
      padding: '8px 0px 8px 16px',
      minHeight: '38px',
      fontSize: tokens.fontSizeBase400,
      fontWeight: tokens.fontWeightRegular,
      lineHeight: tokens.lineHeightBase400,
      '@media (max-width: 1439px)': {
        lineHeight: tokens.lineHeightBase300,
        fontSize: tokens.fontSizeBase300,
        padding: '8px 0px 8px 12px',
      },
      '@media (max-width: 767px)': {
        lineHeight: tokens.lineHeightBase200,
        fontSize: tokens.fontSizeBase200,

      },
    },
  },
  tableContainer: {
    border: `1px solid ${tokens.colorNeutralStroke1}`,
    borderRadius: tokens.borderRadiusXLarge,
    overflow: 'hidden',
    '& table': {
      width: '100%',
      border: 'none',
    },
  },
  tableHeader: {
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase500,
    fontSize: tokens.fontSizeBase500,
    color: tokens.colorNeutralForeground1,
    marginBottom: '1rem',
  },
  columnTitle: {
    color: tokens.colorNeutralForeground1,
    fontWeight: tokens.fontWeightSemibold,
    fontSize: tokens.fontSizeBase400,
    lineHeight: tokens.lineHeightBase400,
    '@media (max-width: 1439px)': {
      lineHeight: tokens.lineHeightBase300,
      fontSize: tokens.fontSizeBase300,
    },
    '@media (max-width: 767px)': {
      lineHeight: tokens.lineHeightBase200,
      fontSize: tokens.fontSizeBase200,
    },
  },
  wrapperSubText: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '1rem',
    fontSize: tokens.fontSizeBase200,
    lineHeight: tokens.lineHeightBase200,
    fontWeight: tokens.fontWeightRegular,

    '@media (max-width: 767px)': {
      fontSize: tokens.fontSizeBase100,
      lineHeight: tokens.lineHeightBase100,
    },
  },
});
export default useClasses;