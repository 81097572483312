interface IData {
  id: string;
  title: string;
  step: string,
  description: string,
}

export const data: IData[] = [
  {
    id: '1',
    step: 'Step 1',
    title: 'First Contact',
    description: 'After you contact us, we will reach out to you within 24 hours to discuss your blog writing needs\n' +
      '                and learn about your brands or brands. Depending on your tech task, we may or may not need to\n' +
      '                consult you for more details. We will also ask you a few questions to understand your target\n' +
      '                audience and the goals your content has to meet.',
  },
  {
    id: '2',
    step: 'Step 2',
    title: 'Content Planning',
    description: 'We then evaluate your needs, we can determine the scale of your task, and agree on the best combination of length, topics, and keywords. We will also offer you the best price and service bundle to meet your current needs. Our blog writing team then creates the outline of your blog posts and the delivery schedule and starts fulfilling the task.',
  },
  {
    id: '3',
    step: 'Step 3',
    title: 'Writing & Editing',
    description: 'Once the planning is done, the structure is detailed and clear,and we have agreed on the estimated delivery time, our skilled writers start their work. They craft compelling content based on the requirements you listed before, and we religiously follow every single point on it. Each piece undergoes editing, checks for clarity, and SEO optimization.  ',
  },
  {
    id: '4',
    step: 'Step 4',
    title: 'Final Review and Delivery',
    description: 'We then perform the final review of your blog post to ensure it is polished and meets the key areas noted during consultation. The blog writing team works with editors to make any changes. Once the order is ready and all the points that needed revision are checked, your contents will be delivered to you ready for publishing.',
  },
];