import { CardDetails } from './BannerCard/types';

import slide1Image from '../../../assets/images/home/offer/slide1.webp';
import slide2Image from '../../../assets/images/home/offer/slide2.webp';
import slide3Image from '../../../assets/images/home/offer/slide3.webp';
import slide4Image from '../../../assets/images/home/offer/slide4.webp';
import slide5Image from '../../../assets/images/home/offer/slide5.webp';

export const cards: CardDetails[] = [
  {
    title: 'All-round Content Services',
    description:
      'Make our content writing agency your brand’s voice and grow your business tenfold.',
    imageSrc: slide1Image,
  },
  {
    title: 'Straight To The Point',
    description:
      'Content on any subject or topic, well-researched and data-rich.',
    imageSrc: slide2Image,
  },
  {
    title: 'Original Writing',
    description: '100% human, no plagiarism, and AI-free.',
    imageSrc: slide3Image,
  },
  {
    title: 'Creative Automation',
    description: 'The fastest wordpress website builder, optimized.',
    imageSrc: slide4Image,
  },
  {
    title: 'Personalized Content',
    description: 'Written in your voice.',
    imageSrc: slide5Image,
  },
];
