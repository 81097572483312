import { Routes, Route } from 'react-router-dom';

import MainPage from '../../pages/MainPage';
import NotFoundPage from '../shared/NotFoundPage';
import ContentWritingServicesPage from '../../pages/ContentWritingServicesPage';
import BlogPage from '../../pages/BlogPage';

export default function RoutingConfig() {
  return (
    <>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route
          path="/content-writing-services"
          element={<ContentWritingServicesPage />}
        />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}
