import { useClassNames } from '../../../styles/global.fluentui.styles';
import { Col, Row } from '../../../components/shared/Grid';
import PricingDataTable from '../../../components/shared/PricingDataTable';
import { pricingTableData } from './pricingTableData';

export default function PriceInfoSection() {
  const globalStyles = useClassNames();

  return (
    <section className={globalStyles.wrapperSection}>
      <div className="container">
        <Row>
          <Col span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 12 }}
               offset={{
                 mobile: 0,
                 tablet: 0,
                 desktop: 2,
                 large: 2,
                 default: 0,
               }}>
            <div className={globalStyles.wrapperHeaderSection}>
              <h1 className={globalStyles.mainHeader}>Content Writing Pricing</h1>
              <h2 className={globalStyles.mainDescription}>
                We have a content writing pricing plan that accommodates a wide range of client needs. Choose the
                package that caters to your unique content writing needs.</h2>
            </div>
          </Col>
          <Col
            offset={{
              mobile: 0,
              tablet: 0,
              desktop: 2,
              large: 2,
              default: 2,
            }}
            span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 8 }}

          >

            <PricingDataTable
              title={pricingTableData.pricing.title}
              data={pricingTableData.pricing.data}
              columns={pricingTableData.pricing.columns}
            />

            <PricingDataTable
              title={pricingTableData.additionalOptions.title}
              data={pricingTableData.additionalOptions.data}
              columns={pricingTableData.additionalOptions.columns}
              subText={pricingTableData.additionalOptions.subText}
            />

            <PricingDataTable
              title={pricingTableData.nicheContent.title}
              data={pricingTableData.nicheContent.data}
              columns={pricingTableData.nicheContent.columns}
              subText={pricingTableData.nicheContent.subText}
            />
          </Col>
        </Row>

      </div>

    </section>
  );
}