import {
  Carousel,
  CarouselAnnouncerFunction,
  CarouselNav,
  CarouselNavButton,
  CarouselSlider,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components';
import * as React from 'react';

import BannerCard from './BannerCard';
import { cards } from './data';

const useClasses = makeStyles({
  bg: {
    backgroundColor: tokens.colorNeutralBackground4,
  },
  wrapper: {
    backgroundColor: tokens.colorNeutralBackground4,
    paddingRight: '40px',
    paddingBottom: '4rem',
    position: 'relative',
    '& [role="region"]': {
      overflow: 'visible',
    },

    '@media (max-width: 1439px)': {
      paddingBottom: '3rem',
    },
    '@media (max-width: 767px)': {
      paddingBottom: '2rem',
    },
  },
  wrapperSlider: {
    gap: '2rem',
    marginBottom: '2rem',
    backgroundColor: tokens.colorNeutralBackground4,
    '& [role="group"]': {
      gap: '2rem',
      overflow: 'visible',
      backgroundColor: tokens.colorNeutralBackground4,
    },
    '@media (max-width: 1439px)': {
      gap: '1.46rem',
    },
    '@media (max-width: 767px)': {
      marginBottom: '1rem',
      gap: '1rem',
    },
  },
  wrapperNav: {
    backgroundColor: tokens.colorNeutralBackground4,

    '& button': {
      width: tokens.spacingVerticalS,
      height: tokens.spacingVerticalS,
      '&[aria-selected="true"]::after': {
        backgroundColor: tokens.colorNeutralForeground1,
        width: tokens.spacingVerticalS,
        height: tokens.spacingVerticalS,
      },
      '&::after': {
        backgroundColor: tokens.colorNeutralForeground3,
      },
    },
  },

  container: {
    maxWidth: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    position: 'relative',
    paddingLeft: '40px',
  },
});

const getAnnouncement: CarouselAnnouncerFunction = (
  index: number,
  totalSlides: number
) => {
  return `Carousel slide ${index + 1} of ${totalSlides}`;
};

export default function OfferSection() {
  const fluentStyles = useClasses();

  return (
    <div className={mergeClasses(fluentStyles.container, fluentStyles.bg)}>
      <Carousel
        className={mergeClasses(fluentStyles.wrapper)}
        groupSize={1}
        circular
        announcement={getAnnouncement}
      >
        <CarouselSlider className={fluentStyles.wrapperSlider}>
          {cards.map((cardInfo, index, array) => (
            <BannerCard
              key={`image-${index}`}
              cardInfo={cardInfo}
              index={index}
              array={array}
            />
          ))}
        </CarouselSlider>

        <CarouselNav
          className={fluentStyles.wrapperNav}
          totalSlides={cards.length}
          appearance="brand"
        >
          {(index) => (
            <CarouselNavButton aria-label={`Carousel Nav Button ${index}`} />
          )}
        </CarouselNav>
      </Carousel>
    </div>
  );
}
