import { Button, mergeClasses } from '@fluentui/react-components';

import { Row, Col } from '../../../components/shared/Grid';
import { useClassNames } from '../../../styles/global.fluentui.styles';

import useClasses from './styles';

export default function ContactUsInfoSection() {
  const globalStyles = useClassNames();
  const styles = useClasses();

  return (
    <section className={globalStyles.wrapperSection}>
      <div className={mergeClasses('container', styles.wrapperContent)}>
        <Row>
          <Col span={{ mobile: 12, tablet: 6, desktop: 6, large: 6, default: 6 }}
               offset={{ mobile: 0, tablet: 3, desktop: 3, large: 3, default: 3 }}
          >
            <span className={styles.text}>Contact us now and receive your content in a matter of days.</span>
            <div className={styles.wrapperBtn}>
              <Button className={styles.buttonWrapper}>Contact us</Button>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}