import { makeStyles, tokens, Button } from '@fluentui/react-components';
import { Link, useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  wrapper: {
    minHeight: 'calc(100vh - 300px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem 0',
  },
  content: {
    textAlign: 'center',
    maxWidth: '600px',
    margin: '0 auto',
    padding: '0 20px',
  },
  title: {
    fontSize: '120px',
    fontWeight: tokens.fontWeightBold,
    color: tokens.colorNeutralForeground1,
    marginBottom: '1rem',
    lineHeight: '1',
    '@media (max-width: 767px)': {
      fontSize: '80px',
    },
  },
  subtitle: {
    fontSize: tokens.fontSizeBase600,
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorNeutralForeground1,
    marginBottom: '1rem',
  },
  description: {
    fontSize: tokens.fontSizeBase400,
    color: tokens.colorNeutralForeground2,
    marginBottom: '2rem',
    lineHeight: tokens.lineHeightBase400,
  },
  buttonsWrapper: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  primaryButton: {
    backgroundColor: tokens.colorNeutralBackgroundInverted,
    color: tokens.colorNeutralForegroundInverted,
    borderRadius: tokens.borderRadiusCircular,
    padding: '0.5rem 2rem',
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase400,
    fontSize: tokens.fontSizeBase400,
    '&:hover': {
      backgroundColor: tokens.colorNeutralBackground2Hover,
    },
  },
  secondaryButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    color: tokens.colorNeutralForeground1,
    borderRadius: tokens.borderRadiusCircular,
    padding: '0.5rem 2rem',
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase400,
    fontSize: tokens.fontSizeBase400,
    '&:hover': {
      backgroundColor: tokens.colorNeutralBackground3,
    },
  },
});

export default function NotFoundPage() {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <h1 className={classes.title}>404</h1>
        <h2 className={classes.subtitle}>Page Not Found</h2>
        <p className={classes.description}>
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable. Please try one of the
          following:
        </p>

        <div className={classes.buttonsWrapper}>
          <Button
            as="button"
            className={classes.secondaryButton}
            onClick={() => navigate(-1)}
          >
            Go Back
          </Button>

          <Link to="/" className={classes.primaryButton}>
            Go to Homepage
          </Link>
        </div>
      </div>
    </div>
  );
}
