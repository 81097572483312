import useClasses from './styles';
import { useClassNames } from '../../../styles/global.fluentui.styles';
import { Col, Row } from '../../../components/shared/Grid';

export default function ContentServicesSection() {
  const globalStyles = useClassNames();
  const styles = useClasses();

  return (
    <section className={globalStyles.wrapperSection}>
      <div className="container">
        <div className={globalStyles.wrapperHeaderSection}>
          <h1 className={globalStyles.mainHeader}>What Content You Get</h1>
        </div>
        <Row rowGap={36}>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 8 }}
            offset={{ mobile: 0, tablet: 0, desktop: 2, large: 2, default: 2 }}
          >
            <div className={styles.wrapperColumn}>
              <h3>Blog Content Optimized for Search Engine</h3>
              <p>
                We ensure your blog content is optimized for search engines for better online visibility and higher
                position on the search results. We perform extensive blog writing keyword research using premium tools.
                Our team is able to select the keywords your target audience uses when looking for similar products to
                lift your position on their search result page.
              </p>
            </div>
          </Col>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 8 }}
            offset={{ mobile: 0, tablet: 0, desktop: 2, large: 2, default: 2 }}
          >
            <div className={styles.wrapperColumn}>
              <h3>Timely Blog Content Delivery</h3>
              <p>
                Are you looking to publish articles regularly? We are keen to keep your content calendar on track.
                Therefore, we ensure prompt delivery of blog writing posts according to the agreed schedule. You can
                outsource the entire content calendar to us and expect to publish each article when required.
              </p>
            </div>
          </Col>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 8 }}
            offset={{ mobile: 0, tablet: 0, desktop: 2, large: 2, default: 2 }}
          >
            <div className={styles.wrapperColumn}>
              <h3>High-Quality Blog Content</h3>
              <p>
                We craft compelling, engaging, high-quality blog writing posts that meet your brand goals. The blog
                writing posts reflect your brand's voice and position you as an authority in your niche. You also get
                blog posts that are ready to publish.
              </p>
            </div>
          </Col>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 8 }}
            offset={{ mobile: 0, tablet: 0, desktop: 2, large: 2, default: 2 }}
          >
            <div className={styles.wrapperColumn}>
              <h3>Content Tailored to Your Needs</h3>
              <p>
                Our writers craft blog posts that are tailored to your needs. This blog writing content engages your
                target audience and positions your brand as an authority in your niche. We advise accordingly on the
                best mix of topics, keywords, and article structures based on your needs.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}