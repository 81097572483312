import { makeStyles, tokens } from '@fluentui/react-components';

const useClasses = makeStyles({
  checkboxGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '8px',
    '@media (min-width: 767px)': {
      gridTemplateColumns: '1fr 1fr',
      gap: '12px',
    },
    '@media (min-width: 1024px)': {
      gap: '16px',
    },
  },
  checkbox: {
    '& label': {
      color: tokens.colorNeutralForeground3,
      fontSize: tokens.fontSizeBase300,
      lineHeight: tokens.lineHeightBase300,
    },
  },
});

export default useClasses;