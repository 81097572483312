import slide1 from '../../../assets/images/home/builderFeatures/slide1.webp';
import slide2 from '../../../assets/images/home/builderFeatures/slide2.webp';
import slide3 from '../../../assets/images/home/builderFeatures/slide3.webp';
import slide4 from '../../../assets/images/home/builderFeatures/slide4.webp';
import slide5 from '../../../assets/images/home/builderFeatures/slide5.webp';
import slide6 from '../../../assets/images/home/builderFeatures/slide6.webp';

export interface BuilderFeature {
  id: string;
  title: string;
  description: string;
  imageSrc: string;
}

export const builderFeatures: BuilderFeature[] = [
  {
    id: '1',
    title: 'You post - We host',
    description:
      'Don\'t stress over servers, infrastructure, and hosting. Just create your blog or website and unleash your creativity.',
    imageSrc: slide1,
  },
  {
    id: '2',
    title: 'Customizable plugins',
    description:
      'Need to add plugins to media website networks to your blog? Our best AI website builder will automatically install the plugins.',
    imageSrc: slide2,
  },
  {
    id: '3',
    title: 'Manage all blogs in one place',
    description:
      'Our customizable dashboard helps you manage all your blogs in one place.',
    imageSrc: slide3,
  },
  {
    id: '4',
    title: 'Save developers’ time and cost',
    description:
      'Our AI website generator lets you create a website without developers, coding skills, or SQL databases.',
    imageSrc: slide4,
  },
  {
    id: '5',
    title: 'Just another website on WordPress',
    description: 'It is familiar, easy to use, and customizable.',
    imageSrc: slide5,
  },
  {
    id: '6',
    title: 'Seamless integration with Contenton AI tools',
    description:
      'If creating a new blog or website in 10 minutes is not crazy enough, listen: You can also automatically generate unique content on the hottest topics using our AI solutions.',
    imageSrc: slide6,
  },
];
