import { makeStyles } from '@fluentui/react-components';


const useClasses = makeStyles({
  wrapperNiches: {
    '@media (max-width: 1439px)': {
      display: 'none',
    },
  },
});

export default useClasses;