import { makeStyles, tokens } from '@fluentui/react-components';

const useClassNames = makeStyles({
  mainHeader: {
    color: tokens.colorNeutralForeground1,
    fontSize: tokens.fontSizeHero900,
    lineHeight: tokens.lineHeightHero900,
    fontWeight: tokens.fontWeightSemibold,
    textAlign: 'center',
    marginBottom: '1rem',
    '@media (max-width: 1439px)': {
      fontSize: tokens.fontSizeHero700,
      lineHeight: tokens.lineHeightHero700,
      marginBottom: '0.5rem',
    },
    '@media (max-width: 767px)': {
      marginBottom: '0.25rem',
    },
  },
  mainDescription: {
    color: tokens.colorNeutralForeground2,
    fontSize: tokens.fontSizeBase500,
    lineHeight: tokens.lineHeightBase500,
    fontWeight: tokens.fontWeightSemibold,
    textAlign: 'center',
    '@media (max-width: 1439px)': {
      fontSize: tokens.fontSizeBase400,
      lineHeight: tokens.lineHeightBase400,
      fontWeight: tokens.fontWeightRegular,
    },
    '@media (max-width: 767px)': {
      // marginBottom: '2rem',
    },
  },
  wrapperHeaderSection: {
    marginBottom: '4rem',
    '@media (max-width: 1439px)': {
      marginBottom: '3rem',
    },
    '@media (max-width: 767px)': {
      marginBottom: '2rem',
    },
  },
  wrapperSection: {
    backgroundColor: tokens.colorNeutralBackground4,
    padding: '4rem 0px',
    '@media (max-width: 1439px)': {
      padding: '3rem 0px',
    },
    '@media (max-width: 767px)': {
      padding: '2rem 0px',
    },
  },
  buttonWrapper: {
    padding: '0.5rem 3.47rem',
    fontSize: tokens.fontSizeBase400,
    lineHeight: tokens.lineHeightBase400,
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorNeutralForegroundInverted,
    backgroundColor: tokens.colorNeutralForeground1,
    borderRadius: tokens.borderRadiusCircular,
  },
  smallButtonWrapper: {
    padding: '0.31rem 1rem',
    fontSize: tokens.fontSizeBase300,
    lineHeight: tokens.lineHeightBase300,
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorNeutralForegroundInverted,
    backgroundColor: tokens.colorNeutralForeground1,
    borderRadius: tokens.borderRadiusCircular,
  },
  linkWrapper: {
    '& a': {
      textDecoration: 'underline',
      fontSize: tokens.fontSizeBase400,
      fontWeight: tokens.fontWeightSemibold,
      lineHeight: tokens.lineHeightBase400,
      color: tokens.colorNeutralForeground2,
      textUnderlineOffset: '4px',
    },
  },
  buttonWrapperLink: {
    '& button': {
      padding: '0',
      background: 'transparent',
      border: '1px solid transparent',
      textDecoration: 'underline',
      minWidth: '1px',
      color: tokens.colorNeutralForeground2,
      fontWeight: tokens.fontWeightSemibold,
      textUnderlineOffset: '4px',
      '&:hover': {
        background: 'transparent',
        border: '1px solid transparent',
      },
      '&:hover:active': {
        background: 'transparent',
        border: '1px solid transparent',
      },
    },
  },
});

export { useClassNames };
