import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useStyles = makeStyles({
  burgerBtn: {
    display: 'none',
    width: '30px',
    height: '20px',
    position: 'relative',
    cursor: 'pointer',
    background: 'none',
    ...shorthands.border('none'),
    ...shorthands.padding(0),
    '@media (max-width: 1439px)': {
      display: 'block',
    },
    '& span': {
      display: 'block',
      position: 'absolute',
      height: '2px',
      width: '100%',
      background: tokens.colorNeutralForeground2,
      ...shorthands.borderRadius('3px'),
      transition: 'all 0.3s ease',
    },
    '& span:nth-child(1)': {
      top: 0,
    },
    '& span:nth-child(2)': {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '& span:nth-child(3)': {
      bottom: 0,
    },
  },
  active: {
    '& span:nth-child(1)': {
      transform: 'rotate(45deg)',
      top: '50%',
    },
    '& span:nth-child(2)': {
      opacity: 0,
    },
    '& span:nth-child(3)': {
      transform: 'rotate(-45deg)',
      top: '50%',
    },
  },
});
