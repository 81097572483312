import React, { MouseEvent } from 'react';
import { useState } from 'react';

import { Col, Row } from '../../../components/shared/Grid';
import { useClassNames } from '../../../styles/global.fluentui.styles';
import { contentWritingNiches } from './data';
import PricingCalculatorModal from '../../../components/modals/PricingCalculatorModal';
import NicheCard from './NicheCard';
import NicheCarousel from './NicheCarousel';

import useClasses from './styles';

export default function NicheContentWritingSection() {
  const [isPricingModalOpen, setIsPricingModalOpen] = useState<string>();
  const globalStyles = useClassNames();
  const styles = useClasses();

  const handlePricingModalOpen = (event: MouseEvent<HTMLButtonElement>) => {
    const niche = event.currentTarget.dataset.niche;
    setIsPricingModalOpen(niche);
  };

  const handlePricingModalClose = () => {
    setIsPricingModalOpen(undefined);
  };

  return (
    <>
      {
        isPricingModalOpen &&
        <PricingCalculatorModal
          isOpen={Boolean(isPricingModalOpen)}
          onDismiss={handlePricingModalClose}
          defaultNicheId={isPricingModalOpen} />
      }
      <section className={globalStyles.wrapperSection}>
        <div className="container">
          <Row>
            <Col
              span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 12 }}
              offset={{
                mobile: 0,
                tablet: 0,
                desktop: 2,
                large: 2,
                default: 0,
              }}
            >
              <div className={globalStyles.wrapperHeaderSection}>
                <h1 className={globalStyles.mainHeader}>Niche content writing</h1>
                <h2 className={globalStyles.mainDescription}>
                  Are you on the lookout for an all-round content writing agency that will guarantee increased traffic
                  to your
                  website? Whatever niche content writing you are interested in, we have you covered.
                </h2>
              </div>
            </Col>

          </Row>

          <Row gap={32} className={styles.wrapperNiches}>
            {contentWritingNiches.map(data => <NicheCard data={data} onClick={handlePricingModalOpen} />)}
          </Row>
        </div>

        <NicheCarousel data={contentWritingNiches} onClick={handlePricingModalOpen} />
      </section>
    </>
  );
}