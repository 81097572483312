import { makeStyles, tokens } from '@fluentui/react-components';

const useClasses = makeStyles({
  wrapperContent: {
    padding: '4rem 0',
    borderRadius: tokens.borderRadiusMedium,
    backgroundColor: tokens.colorNeutralBackground1,
    '@media (max-width: 1439px)': {
      padding: '3rem 0',
    },
    '@media (max-width: 767px)': {
      padding: '2rem 0',
    },
  },
  text: {
    color: tokens.colorNeutralForeground1,
    textAlign: 'center',
    fontWeight: tokens.fontWeightSemibold,
    fontSize: tokens.fontSizeHero900,
    lineHeight: tokens.lineHeightHero900,
    display: 'block',
    marginBottom: '2rem',
    '@media (max-width: 1439px)': {
      fontSize: tokens.fontSizeHero800,
      lineHeight: tokens.lineHeightHero800,
    },
    '@media (max-width: 767px)': {
      fontSize: tokens.fontSizeHero700,
      lineHeight: tokens.lineHeightHero700,
    },
  },
  wrapperBtn: {
    textAlign: 'center',
  },
  buttonWrapper: {
    color: tokens.colorNeutralForegroundInverted,
    fontSize: tokens.fontSizeBase400,
    lineHeight: tokens.lineHeightBase400,
    fontWeight: tokens.fontWeightSemibold,
    backgroundColor: tokens.colorNeutralForeground1,
    borderRadius: tokens.borderRadiusCircular,
    padding: '0.5rem 3.53rem',
  },
});

export default useClasses;