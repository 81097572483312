import { makeStyles, tokens } from '@fluentui/react-components';

const useClasses = makeStyles({
  wrapperCard: {
    width: '25%',
    marginRight: '1rem',
    '@media (max-width: 1439px)': {
      width: '100%',
    },
    '&>div': {
      padding: '1rem',
      backgroundColor: tokens.colorNeutralBackground1,
      borderRadius: tokens.borderRadiusLarge,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      '@media (max-width: 1439px)': {
        padding: '1rem 1rem 1.87rem 1rem',
      },
      '@media (max-width: 767px)': {
        padding: '1rem 1rem 3.25rem 1rem',
      },
    },
    '&:last-child': {
      marginRight: '0rem',
    },
    '& i': {
      fontSize: '48px',
      marginBottom: '1rem',
    },
  },
  wrapperFeatures: {
    display: 'flex !important',
    '@media (max-width: 1439px)': {
      display: 'grid !important',
    },
  },
  infoText: {
    color: tokens.colorNeutralForeground1,
    fontSize: tokens.fontSizeBase400,
    lineHeight: tokens.lineHeightBase400,
  },
});

export default useClasses;