import './App.css';
import ThemeProvider from './providers/ThemeProvider';
import AppLayout from './components/layout/AppLayout';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <AppLayout />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
