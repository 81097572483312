import { makeStyles, tokens } from '@fluentui/react-components';

const useClasses = makeStyles({
  accordionWrapper: {
    backgroundColor: tokens.colorNeutralBackground1,
    padding: '2rem',
    '@media (max-width: 1439px)': {
      padding: '1.5rem',
    },
    '@media (max-width: 767px)': {
      padding: '1rem',
    },
  },
  accordionItem: {
    padding: '1rem 0px',
    borderBottom: `1px solid ${tokens.colorNeutralStrokeSubtle}`,
    '@media (max-width: 1439px)': {
      padding: '0.5rem 0px',
    },
  },
  accordionWrapperHeader: {
    '& button': {
      padding: '0px',
    },
  },
  accordionHeader: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase500,
    '@media (max-width: 1439px)': {
      fontSize: tokens.fontSizeBase400,
      lineHeight: tokens.lineHeightBase400,
    },
    '@media (max-width: 767px)': {
      fontSize: tokens.fontSizeBase300,
      lineHeight: tokens.lineHeightBase300,
    },
  },
  accordionPanel: {
    margin: '0px',
  },
  description: {
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightRegular,
    lineHeight: tokens.lineHeightBase400,
    paddingBottom: '1rem',
    '@media (max-width: 1439px)': {
      paddingBottom: '0.5rem',
    },
    '@media (max-width: 767px)': {
      fontSize: tokens.fontSizeBase200,
      lineHeight: tokens.lineHeightBase200,
    },
  },
});

export default useClasses;