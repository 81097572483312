import { useLocation } from 'react-router-dom';
import RoutingConfig from '../../router/RoutingConfig';
import MainLayout from '../MainLayout/MainLayout';
import { useGlobalStyles } from '../../../hooks/useGlobalStyles';

export default function AppLayout() {
  const { pathname } = useLocation();

  useGlobalStyles();

  const getLayout = () => {
    switch (pathname) {
      case '/':
      default:
        return MainLayout;
    }
  };

  const Layout = getLayout();

  return (
    <Layout>
      <RoutingConfig />
    </Layout>
  );
}
