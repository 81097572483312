import OfferSection from './OfferSection';
import ContentWritingSection from './ContentWritingSection';
import NewsMonitoringSection from './NewsMonitoringSection';
import QuestionsSection from './QuestionsSection';
import TagsSection from './TagsSection';
import ContentAutomationSection from './ContentAutomationSection/ContentAutomationSection';
import MarketingAndServicesSection from './MarketingAndServicesSection';
import MultiWebsiteBuilderSection from './MultiWebsiteBuilderSection';

export default function MainPage() {
  return (
    <>
      <OfferSection />
      <MarketingAndServicesSection />
      <ContentWritingSection />
      <MultiWebsiteBuilderSection />
      <ContentAutomationSection />
      <NewsMonitoringSection />
      <QuestionsSection />
      <TagsSection />
    </>
  );
}
