import { makeStyles, tokens } from '@fluentui/react-components';

const useClasses = makeStyles({
  wrapperCards: {
    '@media (max-width: 1439px)': {
      display: 'none',
    },
  },
  wrapperColumn: {
    backgroundColor: tokens.colorNeutralBackground1,
    borderRadius: tokens.borderRadiusLarge,
    padding: '32px',
    height: '100%',
    '& h3': {
      color: tokens.colorNeutralForeground1,
      marginBottom: '1rem',
      fontSize: tokens.fontSizeHero800,
      lineHeight: tokens.lineHeightHero800,
    },
    '& p': {
      color: tokens.colorNeutralForeground2,
      fontSize: tokens.fontSizeBase400,
      lineHeight: tokens.lineHeightBase400,
    },
  },
  step: {
    display: 'inline-block',
    marginBottom: '0.5rem',
    color: tokens.colorNeutralForeground1,
    fontSize: tokens.fontSizeBase200,
    lineHeight: tokens.lineHeightBase200,
  },
});

export default useClasses;