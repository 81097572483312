import { makeStyles, tokens } from '@fluentui/react-components';

const useClasses = makeStyles({
  wrapperHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapperFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  total: {},
  price: {},
  dottedLine: {
    flex: 1,
    borderBottom: '2px dotted var(--colorNeutralStroke1)',
    marginBottom: '4px',
  },
  wrapperForm: {
    '& label': {
      color: tokens.colorNeutralForeground1,
      fontSize: tokens.fontSizeBase300,
      lineHeight: tokens.lineHeightBase300,
    },
  },
  wrapperBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '12px',
  },
});

export default useClasses;