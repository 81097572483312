import { makeStyles, tokens } from '@fluentui/react-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { TagData } from '../data';

const SWIPER_CONFIG = {
  speed: 1000,
  autoplayDelay: 2000,
  pauseOnHover: true,
} as const;

const useClasses = makeStyles({
  tagText: {
    padding: '0px 0.69rem',
    lineHeight: tokens.lineHeightBase400,
    fontSize: tokens.fontSizeBase400,
    color: tokens.colorNeutralForeground2,
  },
});

interface TagCarouselProps {
  tags: TagData[];
  reverse?: boolean;
  className?: string;
}

export default function TagCarousel(props: TagCarouselProps) {
  const { tags, reverse, className } = props;
  const styles = useClasses();

  return (
    <Swiper
      modules={[Autoplay]}
      slidesPerView="auto"
      loop={true}
      speed={SWIPER_CONFIG.speed}
      autoplay={{
        delay: SWIPER_CONFIG.autoplayDelay,
        disableOnInteraction: false,
        pauseOnMouseEnter: SWIPER_CONFIG.pauseOnHover,
        reverseDirection: reverse,
      }}
      className={className}
    >
      {tags.map((tag) => (
        <SwiperSlide key={tag.id} style={{ width: 'auto' }}>
          <span className={styles.tagText}>{tag.text}</span>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
