import {
  Carousel,
  CarouselAnnouncerFunction,
  CarouselNavContainer,
  CarouselSlider,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components';
import React from 'react';

import { contentServices } from '../data';
import ContentCarouselCard from './ContentCarouselCard';

const useClasses = makeStyles({
  slider: {
    paddingLeft: 'calc((100vw - 1200px) / 2)',
    gap: '2rem',
    '@media (max-width: 1439px)': {
      paddingLeft: '2rem',
    },
  },
  wrapperNavContainer: {
    gap: '2rem',
    marginTop: '1.30rem',
    justifyContent: 'end',
    '& button': {
      maxWidth: '2.5rem',
      '& span': {
        width: '24px',
        height: '24px',
        fontSize: '24px',
      },
      '&:hover': {
        backgroundColor: tokens.colorSubtleBackgroundHover,
        borderRadius: tokens.borderRadiusCircular,
        '& svg': {
          color: tokens.colorNeutralForeground1,
        },
      },
    },
  },
});

const getAnnouncement: CarouselAnnouncerFunction = (
  index: number,
  totalSlides: number,
) => {
  return `Carousel slide ${index + 1} of ${totalSlides}`;
};

const ContentCarousel = () => {
  const fluentStyles = useClasses();

  return (
    <Carousel
      draggable
      circular={false}
      announcement={getAnnouncement}
      groupSize={2}
    >
      <CarouselSlider className={fluentStyles.slider}>
        {contentServices.map((content, index, array) => (
          <ContentCarouselCard
            key={content.id}
            content={content}
            index={index}
            contentServices={array}
          />
        ))}
      </CarouselSlider>
      <div className="container">
        <CarouselNavContainer
          layout="inline"
          next={{
            'aria-label': 'go to next',
          }}
          prev={{
            'aria-label': 'go to prev',
          }}
          className={mergeClasses('', fluentStyles.wrapperNavContainer)}
        />
      </div>

    </Carousel>
  );
};

export default ContentCarousel;