import { makeStyles, tokens } from '@fluentui/react-components';

const useClasses = makeStyles({
  wrapperColumn: {
    padding: '2rem',
    backgroundColor: tokens.colorNeutralBackground1,
    borderRadius: tokens.borderRadiusLarge,
    height: '100%',
    '@media (max-width: 1439px)': {
      padding: '1.5rem',
    },
    '& h3': {
      color: tokens.colorNeutralForeground1,
      marginBottom: '0.5rem',
      fontSize: tokens.fontSizeHero800,
      lineHeight: tokens.lineHeightHero800,
      fontWeight: tokens.fontWeightSemibold,
      paddingTop: '1rem',
      '@media (max-width: 1439px)': {
        fontSize: tokens.fontSizeBase500,
        lineHeight: tokens.lineHeightBase500,
      },
      '@media (max-width: 767px)': {
        marginBottom: '1rem',
      },
    },
    '& p': {
      color: tokens.colorNeutralForeground2,
      fontSize: tokens.fontSizeBase400,
      lineHeight: tokens.lineHeightBase400,
      fontWeight: tokens.fontWeightRegular,
      '@media (max-width: 767px)': {
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
      },
    },
    '& i': {
      fontSize: '48px',
    },
  },
});

export default useClasses;