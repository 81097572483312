import { FC, FocusEvent, ChangeEvent } from 'react';
import {
  Input,
  Field,
  useId,
} from '@fluentui/react-components';
import { useField } from 'formik';

interface FormikNumberInputProps {
  name: string;
  label: string;
  min?: number;
  max?: number;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  description?: string;
  onChange?: (value: number) => void;
  placeholder?: string;
}

const FormikNumberInput: FC<FormikNumberInputProps> = (props) => {
  const {
    name,
    label,
    min,
    max,
    required = false,
    disabled = false,
    className,
    description,
    onChange,
    placeholder,
  } = props;

  const [field, meta, helpers] = useField(name);
  const id = useId();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === '') {
      helpers.setValue('');
      return;
    }

    if (!/^\d*\.?\d*$/.test(value)) {
      return;
    }

    const numValue = parseFloat(value);

    if (!isNaN(numValue)) {
      helpers.setValue(value);
      onChange?.(numValue);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === '') {
      const defaultValue = min || 0;
      helpers.setValue(defaultValue);
      onChange?.(defaultValue);
      return;
    }

    let numValue = parseFloat(value);

    // Применяем ограничения min/max
    if (min !== undefined && numValue < min) {
      numValue = min;
    }
    if (max !== undefined && numValue > max) {
      numValue = max;
    }

    helpers.setValue(numValue);
    helpers.setTouched(true);
    onChange?.(numValue);
  };

  return (
    <Field
      label={label}
      required={required}
      validationState={meta.touched && meta.error ? 'error' : undefined}
      validationMessage={meta.touched ? meta.error : undefined}
      hint={description}
      className={className}
    >
      <Input
        id={id}
        type="text"
        value={field.value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        placeholder={placeholder}
      />
    </Field>
  );
};

export default FormikNumberInput;