import { makeStyles, tokens } from '@fluentui/react-components';
import TagCarousel from './TagCarousel';
import { useMemo } from 'react';
import { API_TAGS_DATA, TAGS_DATA } from './data';
import 'swiper/css';

const useClasses = makeStyles({
  section: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  carousel: {
    maxWidth: '100%',
    padding: '10px',
    backgroundColor: tokens.colorNeutralBackground1,
  },
  carouselWithBorder: {
    borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
  },
});

export default function TagsSection() {
  const styles = useClasses();

  const carouselClasses = useMemo(
    () => ({
      first: `${styles.carousel} ${styles.carouselWithBorder}`,
      second: styles.carousel,
    }),
    [styles]
  );

  return (
    <section className={styles.section}>
      <TagCarousel tags={TAGS_DATA} className={carouselClasses.first} />
      <TagCarousel
        tags={API_TAGS_DATA}
        className={carouselClasses.second}
        reverse
      />
    </section>
  );
}
