import { wordCountPricing, niches, additionalOptions } from '../data';

export const createWordCountMap = () => {
  const map = new Map();
  wordCountPricing.forEach(pricing => {
    map.set(pricing.minWords, pricing);
  });
  return map;
};

export const createNichesMap = () => {
  const map = new Map();
  niches.forEach(niche => {
    map.set(niche.id, niche.priceModifier);
  });
  return map;
};

export const createOptionsMap = () => {
  const map = new Map();
  additionalOptions.forEach(option => {
    map.set(option.id, option.priceModifier);
  });
  return map;
};

export const WORD_COUNT_MAP = createWordCountMap();
export const NICHES_MAP = createNichesMap();
export const OPTIONS_MAP = createOptionsMap();