import React, { CSSProperties } from 'react';
import { makeStyles } from '@fluentui/react-components';

const BREAKPOINTS = {
  mobile: 0, // (0-767px)
  tablet: 768, // (768-1439px)
  desktop: 1440, // (1440-1919px)
  large: 1920, // (1920px+)
};

interface GridProps {
  children: React.ReactNode;
  className?: string;
  gap?: number | ResponsiveModel;
  style?: CSSProperties;
}

interface ResponsiveModel {
  mobile?: number; // 360-767px
  tablet?: number; // 768-1439px
  desktop?: number; // 1440-1919px
  large?: number; // 1920px+
  default: number;
}

interface ColProps extends GridProps {
  span?: number | ResponsiveModel;
  offset?: number | ResponsiveModel;
}

type ColSpan = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

const useStyles = makeStyles({
  row: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gap: '16px',
    width: '100%',
  },
  col1: { gridColumn: 'span 1' },
  col2: { gridColumn: 'span 2' },
  col3: { gridColumn: 'span 3' },
  col4: { gridColumn: 'span 4' },
  col5: { gridColumn: 'span 5' },
  col6: { gridColumn: 'span 6' },
  col7: { gridColumn: 'span 7' },
  col8: { gridColumn: 'span 8' },
  col9: { gridColumn: 'span 9' },
  col10: { gridColumn: 'span 10' },
  col11: { gridColumn: 'span 11' },
  col12: { gridColumn: 'span 12' },
});

interface GapProps {
  gap?: number | ResponsiveModel;
  columnGap?: number | ResponsiveModel;
  rowGap?: number | ResponsiveModel;
}

interface GridProps extends GapProps {
  children: React.ReactNode;
  className?: string;
}

const useResponsiveValue = (
  prop: number | ResponsiveModel | undefined,
  defaultValue: number,
): number => {
  const [currentValue, setCurrentValue] = React.useState<number>(
    typeof prop === 'number'
      ? prop
      : typeof prop === 'object'
        ? prop.default
        : defaultValue,
  );

  React.useEffect(() => {
    const handleResize = () => {
      if (typeof prop === 'object') {
        const width = window.innerWidth;
        if (width >= BREAKPOINTS.large && prop.large !== undefined) {
          setCurrentValue(prop.large);
        } else if (width >= BREAKPOINTS.desktop && prop.desktop !== undefined) {
          setCurrentValue(prop.desktop);
        } else if (width >= BREAKPOINTS.tablet && prop.tablet !== undefined) {
          setCurrentValue(prop.tablet);
        } else if (width >= BREAKPOINTS.mobile && prop.mobile !== undefined) {
          setCurrentValue(prop.mobile);
        } else {
          setCurrentValue(prop.default);
        }
      } else if (typeof prop === 'number') {
        setCurrentValue(prop);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [prop]);

  return currentValue;
};

export const Row: React.FC<GridProps> = (props) => {
  const {
    children,
    className = '',
    gap,
    columnGap,
    rowGap,
    style,
  } = props;
  const styles = useStyles();

  const effectiveColumnGap = columnGap ?? gap ?? 16;
  const effectiveRowGap = rowGap ?? gap ?? 16;

  const currentColumnGap = useResponsiveValue(effectiveColumnGap, 16);
  const currentRowGap = useResponsiveValue(effectiveRowGap, 16);

  return (
    <div
      className={`${styles.row} ${className}`}
      style={{
        columnGap: `${currentColumnGap}px`,
        rowGap: `${currentRowGap}px`,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const Col: React.FC<ColProps> = ({
                                          children,
                                          span = 12,
                                          offset = 0,
                                          className = '',
                                        }) => {
  const styles = useStyles();
  const currentSpan = useResponsiveValue(span, 12);
  const currentOffset = useResponsiveValue(offset, 0);

  const colSpan = Math.min(12, Math.max(1, currentSpan)) as ColSpan;

  const style: React.CSSProperties = {
    gridColumn:
      currentOffset > 0
        ? `${currentOffset + 1} / span ${colSpan}`
        : `span ${colSpan}`,
  };

  return (
    <div
      className={`${
        styles[`col${colSpan}` as keyof typeof styles]
      } ${className}`}
      style={style}
    >
      {children}
    </div>
  );
};
