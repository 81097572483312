import {
  Image,
  mergeClasses,
} from '@fluentui/react-components';
import { Link } from 'react-router-dom';
import { useClassNames } from '../../../styles/global.fluentui.styles';
import { Col, Row } from '../Grid';

import useClasses from './styles';

interface ContentSectionProps {
  headerTitle: string;
  headerDescription: string;
  mainTitle: string;
  mainDescription: string;
  imageSrc: string;
  linkPath: string;
  linkText?: string;
  imagePosition?: 'left' | 'right';
}

export default function ContentWithImageSection(props: ContentSectionProps) {
  const {
    headerTitle,
    headerDescription,
    mainTitle,
    mainDescription,
    imageSrc,
    linkPath,
    linkText = 'Learn More',
    imagePosition = 'left',
  } = props;

  const styles = useClasses();
  const globalStyles = useClassNames();

  const renderContent = () => {
    const imageColumn = (
      <Col
        className={styles.wrapperColumn}
        span={{
          mobile: 12,
          tablet: 6,
          desktop: 6,
          large: 6,
          default: 12,
        }}
      >
        <Image
          className={imagePosition === 'left' ? styles.imageLeft : styles.imageRight}
          src={imageSrc}
        />
      </Col>
    );

    const textColumn = (
      <Col
        className={mergeClasses(
          styles.wrapperColumn,
          styles.wrapperColumnWithText,
        )}
        span={{
          mobile: 12,
          tablet: 6,
          desktop: 6,
          large: 6,
          default: 12,
        }}
      >
        <h3 className={styles.textInColumn}>{mainTitle}</h3>
        <span className={styles.subTextInColumn}>{mainDescription}</span>
        <div className={globalStyles.linkWrapper}>
          <Link to={linkPath}>{linkText}</Link>
        </div>
      </Col>
    );

    return imagePosition === 'left'
      ? <>{imageColumn}{textColumn}</>
      : <>{textColumn}{imageColumn}</>;
  };

  return (
    <section className={styles.wrapper}>
      <div className="container">
        <Row>
          <Col
            offset={{
              mobile: 0,
              tablet: 0,
              desktop: 2,
              large: 2,
              default: 2,
            }}
            span={{
              mobile: 12,
              tablet: 12,
              desktop: 8,
              large: 8,
              default: 8,
            }}
          >
            <div className={globalStyles.wrapperHeaderSection}>
              <h1 className={globalStyles.mainHeader}>{headerTitle}</h1>
              <h2 className={globalStyles.mainDescription}>{headerDescription}</h2>
            </div>
          </Col>
        </Row>

        <Row className={styles.mainRowWrapper}>
          {renderContent()}
        </Row>
      </div>
    </section>
  );
};