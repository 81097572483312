import * as React from 'react';
import { Button, mergeClasses } from '@fluentui/react-components';

import { Col, Row } from '../../../components/shared/Grid';
import Breadcrumbs from '../../../components/shared/Breadcrumbs';

import useClasses from './styles';

export default function MainBanner() {
  const styles = useClasses();

  return (
    <section className={styles.backgroundImage}>
      <div className={mergeClasses('container', styles.fullHeight)}>
        <Breadcrumbs items={[
          {
            path: '/',
            label: <i className="icon icon-Home" />,
          },
          {
            path: '/content-writing-services',
            label: 'Content writing services',
          },
        ]} />
        <Row className={styles.fullHeight}>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 8 }}
            offset={{ mobile: 0, tablet: 0, desktop: 2, default: 2 }}
            className={styles.wrapperColumn}>
            <h1 className={styles.mainText}>Best content writing service providers </h1>
            <h2 className={styles.subText}>Need powerful niche content that instantly creates impressions, generates
              leads, and converts? Our content
              writing services tell your brand story 360 degrees with a 100% human touch. Get all-natural, organic
              content
              written by seasoned SEO gurus.</h2>
            <div>
              <Button
                className={styles.buttonWrapper}
                size="small"
                shape="square"
                appearance="primary"
              >
                Book a call
              </Button>
            </div>
          </Col>

        </Row>

      </div>
    </section>
  );
}