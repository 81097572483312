import slide1 from '../../../assets/images/contentWritingServices/contentKey/slide1.webp';
import slide2 from '../../../assets/images/contentWritingServices/contentKey/slide2.webp';
import slide3 from '../../../assets/images/contentWritingServices/contentKey/slide3.webp';
import slide4 from '../../../assets/images/contentWritingServices/contentKey/slide5.webp';
import slide5 from '../../../assets/images/contentWritingServices/contentKey/slide5.webp';
import slide6 from '../../../assets/images/contentWritingServices/contentKey/slide6.webp';
import slide7 from '../../../assets/images/contentWritingServices/contentKey/slide7.webp';
import slide8 from '../../../assets/images/contentWritingServices/contentKey/slide8.webp';

export interface WritingServiceSlide {
  id: string;
  title: string;
  description: string;
  imageSrc: string;
}

export const writingServiceSlides: WritingServiceSlide[] = [
  {
    id: '1',
    title: 'Article Writing',
    description: 'Our content writing service delivers articles with the right keyword density to enhance search rankings. Our authors in article writing will craft top-notch content and increase organic traffic.',

    imageSrc: slide1,
  },
  {
    id: '2',
    title: 'Blog Writing',
    description: 'Outsource your content writing needs to us and elevate your brand to new levels. We include content strategy as part of the blog posts writing service to help your brand grow.',
    imageSrc: slide2,
  },
  {
    id: '3',
    title: 'SEO Content Writing',
    description: 'At Contenton, we are not the best SEO content writing service providers by chance. We are skillful at using target keywords to optimize SEO content for higher ranking on search engines.',
    imageSrc: slide3,
  },
  {
    id: '4',
    title: 'Copywriting',
    description: 'Our copywriting department is highly gifted in persuasive writing. They will assist you in crafting statements that communicate your business value while capturing your customers\' needs, increasing revenue generation.',
    imageSrc: slide4,
  },
  {
    id: '5',
    title: 'Ghostwriting',
    description: 'Our content writing agency offers curated ghostwriting services. Thanks to our ghostwriting services, we help you pen down your memorable experiences and take full credit for them.',
    imageSrc: slide5,

  },
  {
    id: '6',
    title: 'Press Releases Writing',
    description: 'Contenton writers are adept at crafting killer press releases. Our prowess at press release writing is part of the reason why we are the best content writing service providers around.',
    imageSrc: slide6,

  },
  {
    id: '7',
    title: 'Product Descriptions Writing',
    description: 'Our team offers exceptional product description writing services. They guarantee catchy headlines for your product reviews, laden with a creative flair to help push your products and boost sales.',
    imageSrc: slide7,
  },
  {
    id: '8',
    title: 'Website Content Writing',
    description: 'Tired of dealing with disengagement? Why not outsource your website content writing to our pool of talented writers? We guarantee you authoritative, thought-provoking, coherently structured, and informative content.',
    imageSrc: slide8,
  },
];