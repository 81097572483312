import React, { MouseEvent } from 'react';

import { ContentWritingNiche } from '../data';
import Icon from '../../../../components/shared/Icon';
import { Button, makeStyles, tokens } from '@fluentui/react-components';
import { Col } from '../../../../components/shared/Grid';
import { useClassNames } from '../../../../styles/global.fluentui.styles';

const useClasses = makeStyles({
  wrapperColumn: {
    minHeight: '16.75rem',
    '& > div': {
      borderRadius: tokens.borderRadiusLarge,
      backgroundColor: tokens.colorNeutralBackground1,
      padding: '1rem',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    '& i': {
      fontSize: '48px',
    },
  },
  description: {
    color: tokens.colorNeutralForeground2,
    fontWeight: tokens.fontWeightRegular,
    fontSize: tokens.fontSizeBase400,
    lineHeight: tokens.lineHeightBase400,
  },
  header: {
    color: tokens.colorNeutralForeground1,
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase500,
    marginBottom: '0.63rem',
  },
  wrapperIcon: {
    marginBottom: '1rem',
    fontSize: '48px',
  },
});

type NicheCardProps = {
  data: ContentWritingNiche;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;

}
export default function NicheCard(props: NicheCardProps) {
  const { data, onClick } = props;
  const styles = useClasses();
  const globalStyles = useClassNames();

  return (
    <Col span={3} key={data.id} className={styles.wrapperColumn}>
      <div>
        <div>
          <div className={styles.wrapperIcon}>
            <Icon iconName={data.iconClassName} pathCount={data.pathCount} />
          </div>
          <h1 className={styles.header}>{data.title}</h1>
          <h4>{data.description}</h4>
        </div>

        <div className={globalStyles.buttonWrapperLink}>
          <Button onClick={onClick} data-niche={data.id}>Calculate</Button>
        </div>
      </div>
    </Col>
  );
}