export enum PriceModifierType {
  PERCENTAGE = 'percentage',
  FIXED = 'fixed',
  CUSTOM = 'custom'
}

interface WordCountPricing {
  id: string;
  wordRange: string;
  pricePerWord: string;
  minWords: number;
  maxWords: number;
}

interface AdditionalOption {
  id: string;
  name: string;
  priceModifier: {
    type: PriceModifierType;
    value: number | string;
  };
}

interface NicheOption {
  id: string;
  name: string;
  priceModifier: {
    type: PriceModifierType;
    value: number | string;
  };
}

export const wordCountPricing: WordCountPricing[] = [
  { id: 'wc1', wordRange: '>5000 words', pricePerWord: '0.08', minWords: 0, maxWords: 5000 },
  { id: 'wc2', wordRange: '5000 – 20,000 words', pricePerWord: '0.06', minWords: 5000, maxWords: 20000 },
  { id: 'wc3', wordRange: '20,000 – 100,000 words', pricePerWord: '0.05', minWords: 20000, maxWords: 100000 },
  { id: 'wc4', wordRange: '<100,000 words', pricePerWord: 'custom', minWords: 100000, maxWords: Infinity },
];

export const additionalOptions: AdditionalOption[] = [
  {
    id: 'opt1',
    name: 'Additional Research, using top industry sources and data',
    priceModifier: { type: PriceModifierType.PERCENTAGE, value: 25 },
  },
  {
    id: 'opt2',
    name: 'Urgent Delivery',
    priceModifier: { type: PriceModifierType.PERCENTAGE, value: 50 },
  },
  {
    id: 'opt3',
    name: 'Interview-Based Content',
    priceModifier: { type: PriceModifierType.PERCENTAGE, value: 30 },
  },
  {
    id: 'opt4',
    name: 'Unique Visual Content',
    priceModifier: { type: PriceModifierType.FIXED, value: 7 },
  },
  {
    id: 'opt5',
    name: 'Content Localization',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'translation' },
  },
  {
    id: 'opt6',
    name: 'Article writing',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'free' },
  },
  {
    id: 'opt7',
    name: 'Blog Writing',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'free' },
  },
  {
    id: 'opt8',
    name: 'Content Marketing & Strategy',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'contact' },
  },
  {
    id: 'opt9',
    name: 'Copywriting',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'free' },
  },
  {
    id: 'opt10',
    name: 'Ghostwriting',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'free' },
  },
  {
    id: 'opt11',
    name: 'Press Releases Writing',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'free' },
  },
  {
    id: 'opt12',
    name: 'Product Descriptions Writing',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'free' },
  },
  {
    id: 'opt13',
    name: 'SEO optimized content Writing',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'free' },
  },
  {
    id: 'opt14',
    name: 'Website Content Writing',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'free' },
  },
];

export const niches: NicheOption[] = [
  {
    id: 'niche1',
    name: 'SaaS & Technical',
    priceModifier: { type: PriceModifierType.PERCENTAGE, value: 20 },
  },
  {
    id: 'niche2',
    name: 'CBD Content',
    priceModifier: { type: PriceModifierType.PERCENTAGE, value: 20 },
  },
  {
    id: 'niche3',
    name: 'Blockchain & Crypto',
    priceModifier: { type: PriceModifierType.PERCENTAGE, value: 20 },
  },
  {
    id: 'niche4',
    name: 'iGaming & Casino',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'free' },
  },
  {
    id: 'niche5',
    name: 'B2B content',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'free' },
  },
  {
    id: 'niche6',
    name: 'Creative content',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'free' },
  },
  {
    id: 'niche7',
    name: 'Local content',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'free' },
  },
  {
    id: 'niche8',
    name: 'News and analytics',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'free' },
  },
  {
    id: 'niche9',
    name: 'Medical content',
    priceModifier: { type: PriceModifierType.PERCENTAGE, value: 30 },
  },
  {
    id: 'niche10',
    name: 'E-commerce content',
    priceModifier: { type: PriceModifierType.CUSTOM, value: 'free' },
  },
];