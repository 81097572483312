import ContentWithImageSection from '../../../components/shared/ContentWithImageSection';

import image from '../../../assets/images/home/marketing/image1.webp';

export default function MarketingAndServicesSection() {

  return (
    <ContentWithImageSection
      headerTitle="Content Marketing & Content Strategy Services"
      headerDescription="Elevate your brand and watch your revenue soar. Contenton turns creativity into profit with content marketing that drives growth. Our tailored strategies boost your SEO, grow your audience, and maximize ROI across all niches. From planning to execution, we craft content that converts and delivers results."
      mainTitle="A content strategy begins by aligning content efforts with overall business goals. It defines the company's core mission, vision, and values"
      mainDescription="Next comes content marketing, which applies content to achieve specific marketing objectives that support broader business goals. This ensures consistency of marketing content with the general course of the company"
      imageSrc={image}
      linkPath="/"
    />
  );
}
