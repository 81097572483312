import { FC } from 'react';
import { Combobox, Option, Field, useId } from '@fluentui/react-components';
import { useField } from 'formik';

interface OptionItem {
  id: string | number;
  name: string;
}

interface FormikComboboxProps {
  name: string;
  label: string;
  options: OptionItem[];
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  description?: string;
  getOptionLabel?: (option: OptionItem) => string;
  getOptionValue?: (option: OptionItem) => string;
}

const FormikCombobox: FC<FormikComboboxProps> = (props: FormikComboboxProps) => {
  const {
    name,
    label,
    options,
    required = false,
    disabled = false,
    placeholder,
    className,
    description,
    getOptionLabel = (option) => option.name,
    getOptionValue = (option) => option.id,
  } = props;
  const [field, meta, helpers] = useField(name);
  const id = useId();

  const validationState = meta.touched && meta.error ? 'error' : undefined;
  const validationMessage = meta.touched ? meta.error : undefined;

  const handleOptionSelect = (_: any, data: { optionValue?: string | number }) => {
    if (data.optionValue !== undefined) {
      helpers.setValue(data.optionValue);
      helpers.setTouched(true);
    }
  };

  const selectedOption = options.find(
    option => getOptionValue(option) === field.value,
  );

  const selectedText = selectedOption ? getOptionLabel(selectedOption) : '';

  return (
    <Field
      label={label}
      required={required}
      validationState={validationState}
      validationMessage={validationMessage}
      hint={description}
      className={className}
    >
      <Combobox
        id={id}
        {...field}
        value={selectedText}
        placeholder={placeholder}
        disabled={disabled}
        onOptionSelect={handleOptionSelect}
      >
        {options.map((option) => (
          <Option
            key={getOptionValue(option)}
            text={getOptionLabel(option)}
            value={getOptionValue(option) as any}
          >
            {getOptionLabel(option)}
          </Option>
        ))}
      </Combobox>
    </Field>
  );
};

export default FormikCombobox;