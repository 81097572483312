import { mergeClasses } from '@fluentui/react-components';

interface IconProps {
  iconName: string;
  pathCount?: number;
  classNames?: string;
}

export default function Icon(props: IconProps) {
  const { iconName, pathCount = 0, classNames } = props;
  if (pathCount === 0) {
    return <i className={iconName} />;
  }

  return (
    <i className={mergeClasses(iconName, classNames)}>
      {[...Array(pathCount)].map((_, index) => (
        <span key={index} className={`path${index + 1}`} />
      ))}
    </i>
  );
};