import { useState } from 'react';
import {
  Image,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components';
import { useTheme } from '../../../hooks/useTheme';
import imgLogoLight from '../../../assets/images/logo/smallLogoLight.svg';
import imgLogoDark from '../../../assets/images/logo/smallLogoDark.svg';
import { Link as LinkType } from './types';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import { BurgerButton } from './BurgerButton/BurgerButton';
import { Link } from 'react-router-dom';

const useClasses = makeStyles({
  header: {
    padding: '0.5rem 0',
    backgroundColor: tokens.colorNeutralBackground4,
    position: 'sticky',
    top: 0,
    zIndex: 100,
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default function AppHeader() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const classes = useClasses();
  const { isDarkTheme } = useTheme();

  const links: LinkType[] = [
    { path: '/content-writing-services', title: 'Content writing services' },
    { path: '/publishing', title: 'Publishing' },
    { path: '/pricing', title: 'Pricing' },
    { path: '/blog', title: 'Blog' },
  ];

  const logo = isDarkTheme ? imgLogoDark : imgLogoLight;

  return (
    <>
      <header className={classes.header}>
        <div className={mergeClasses('container')}>
          <div className={classes.headerContent}>
            <Link to="/" className={classes.logoLink}>
              <Image src={logo} alt="Logo" />
            </Link>

            <DesktopHeader
              isDarkTheme={isDarkTheme}
              logo={logo}
              links={links}
            />

            <BurgerButton
              isOpen={isMenuOpen}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
          </div>
        </div>
      </header>

      <MobileHeader
        isDarkTheme={isDarkTheme}
        logo={logo}
        links={links}
        isOpen={isMenuOpen}
      />
    </>
  );
}
