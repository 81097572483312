import MainBanner from './MainBanner';
import BlogWritingServicesWorkSection from './BlogWritingServicesWorkSection';
import ContentServicesSection from './ContentServicesSection';
import WritingServiceStepsSection from './WritingServiceStepsSection';
import BlogServicesSection from './BlogServicesSection';

export default function BlogPage() {
  return (
    <>
      <MainBanner />
      <BlogWritingServicesWorkSection />
      <ContentServicesSection />
      <WritingServiceStepsSection />
      <BlogServicesSection />
    </>
  );
}