import { makeStyles, tokens } from '@fluentui/react-components';

const useClasses = makeStyles({
  wrapper: {
    backgroundColor: tokens.colorNeutralBackground4,
    padding: '4rem 0px',
    '@media (max-width: 1439px)': {
      padding: '3rem 0px',
    },
    '@media (max-width: 767px)': {
      padding: '2rem 0px',
    },
  },
  mainRowWrapper: {
    backgroundColor: tokens.colorNeutralBackground1,
    borderRadius: tokens.borderRadiusLarge,
  },
  wrapperColumn: {
    height: '37.5rem',
    minHeight: '37.5rem',
    '& img': {
      width: '100%',
      height: '100%',
    },
    '@media (max-width: 1439px)': {
      height: '22.3rem',
      minHeight: '22.3rem',
    },
    '@media (max-width: 767px)': {
      height: 'auto',
      minHeight: 'auto',
    },
  },
  wrapperColumnWithText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0px 4rem',
    '@media (max-width: 1439px)': {
      padding: '0px 1.5rem',
    },
    '@media (max-width: 767px)': {
      padding: '1rem 1rem 0rem 1rem',
      gridRow: '1',
    },
  },
  textInColumn: {
    marginBottom: '1rem',
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase500,
    color: tokens.colorNeutralForeground1,
    fontSize: tokens.fontSizeBase500,
    '@media (max-width: 1439px)': {
      lineHeight: tokens.lineHeightBase300,
      fontSize: tokens.fontSizeBase300,
    },
  },
  subTextInColumn: {
    marginBottom: '1rem',
    fontWeight: tokens.fontWeightRegular,
    lineHeight: tokens.lineHeightBase400,
    color: tokens.colorNeutralForeground1,
    fontSize: tokens.fontSizeBase400,
    '@media (max-width: 1439px)': {
      lineHeight: tokens.lineHeightBase300,
      fontSize: tokens.fontSizeBase300,
    },
    '@media (max-width: 767px)': {
      lineHeight: tokens.lineHeightBase200,
      fontSize: tokens.fontSizeBase200,
    },
  },
  imageLeft: {
    filter: 'brightness(0.8)',
    borderTopLeftRadius: tokens.borderRadiusLarge,
    borderBottomLeftRadius: tokens.borderRadiusLarge,
    '@media (max-width: 767px)': {
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
  imageRight: {
    filter: 'brightness(0.8)',
    borderTopRightRadius: tokens.borderRadiusLarge,
    borderBottomRightRadius: tokens.borderRadiusLarge,
    '@media (max-width: 767px)': {
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
});

export default useClasses;