import React, { MouseEvent } from 'react';
import { Button, CarouselCard, makeStyles, mergeClasses, tokens } from '@fluentui/react-components';

import Icon from '../../../../components/shared/Icon';
import { ContentWritingNiche } from '../data';
import { useClassNames } from '../../../../styles/global.fluentui.styles';

const useClasses = makeStyles({
  slider: {
    '@media (max-width: 1439px)': {
      paddingLeft: '2rem',
    },
  },
  wrapperIcon: {
    marginBottom: '1rem',
    fontSize: '48px',
  },
  wireframe: {
    display: 'flex',
    flexDirection: 'column',
    height: '22rem',
    backgroundColor: tokens.colorNeutralBackground1,
    position: 'relative',
    borderRadius: '5px',
    padding: '1rem',
    '@media (max-width: 1439px)': {
      height: '16.8rem',
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  wireframeSize: {
    minWidth: '100px',
    maxWidth: '24rem',
  },
  description: {
    color: tokens.colorNeutralForeground2,
    fontWeight: tokens.fontWeightRegular,
    fontSize: tokens.fontSizeBase400,
    lineHeight: tokens.lineHeightBase400,
  },
  header: {
    color: tokens.colorNeutralForeground1,
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase500,
    marginBottom: '0.63rem',
  },
});

interface NicheCardCarouselProps {
  data: ContentWritingNiche;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export default function NicheCardCarousel(props: NicheCardCarouselProps) {
  const { data, onClick } = props;
  const styles = useClasses();
  const globalStyles = useClassNames();

  return (
    <CarouselCard autoSize key={data.id}>
      <div className={mergeClasses(styles.wireframe, styles.wireframeSize)}>
        <div>
          <div className={styles.wrapperIcon}>
            <Icon iconName={data.iconClassName} pathCount={data.pathCount} />
          </div>
          <h1 className={styles.header}>{data.title}</h1>
          <h4 className={styles.description}>{data.description}</h4>
        </div>

        <div className={globalStyles.buttonWrapperLink}>
          <Button onClick={onClick} data-niche={data.id}>Calculate</Button>
        </div>
      </div>
    </CarouselCard>
  );
}