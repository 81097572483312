export interface ContentWritingNiche {
  id: string;
  title: string;
  description: string;
  iconClassName: string;
  pathCount: number;
}

export const contentWritingNiches: ContentWritingNiche[] = [
  {
    id: 'niche1',
    title: 'Saas content writing',
    description: 'Our authors in SaaS content are writing craft top-notch content that promotes brand awareness. We help you gain customer trust.',
    iconClassName: 'icon-DocumentSASS',
    pathCount: 0,
  },
  {
    id: 'niche2',
    title: 'Technical content writing',
    description: 'Convey complex information about your brand to the target audience via easy-to-understand user manuals, and guides.',
    iconClassName: 'icon-ViewDesktop',
    pathCount: 5,
  },
  {
    id: 'niche3',
    title: 'Crypto content writing',
    description: 'Professionally crafted news-flash crypto content writing and niche educational articles to convert your readers into frequent visitors.',
    iconClassName: 'icon-B',
    pathCount: 40,
  },
  {
    id: 'niche4',
    title: 'iGaming & Casino content writing',
    description: 'Grow your gambling business 10x or more with our iGaming & casino content writing.',
    iconClassName: 'icon-Games',
    pathCount: 5,
  },
  {
    id: 'niche5',
    title: 'B2B content writing',
    description: 'Our B2B content writing experts will help push your brand\'s reputation and credibility in the industry of your choice.',
    iconClassName: 'icon-B2BCase',
    pathCount: 10,
  },
  {
    id: 'niche6',
    title: 'Creative content writing',
    description: 'Whether it is a memoir, biography, or fictional writing, our creative content writing team can deliver it.',
    iconClassName: 'icon-Color',
    pathCount: 5,
  },
  {
    id: 'niche777',
    title: 'Financial content writing',
    description: 'Educate your readers about the complex forex, stock, crypto, CFD, and other financial markets together with our writers.',
    iconClassName: 'icon-MoneySettings',
    pathCount: 2,
  },
  {
    id: 'niche8888',
    title: 'CBD content writing',
    description: 'Build the credibility and authority of your CBD-focused brand with engaging and informative content.',
    iconClassName: 'icon-Union',
    pathCount: 5,
  },
  {
    id: 'niche7',
    title: 'Local content writing',
    description: 'Educate your readers about the complex forex, stock, crypto, CFD, and other financial markets together with our writers.',
    iconClassName: 'icon-GlobeLocation',
    pathCount: 5,
  },
  {
    id: 'niche8',
    title: 'News and analytics content writing',
    description: 'Our competent news and analytics content writing team crafts factual quality content.',
    iconClassName: 'icon-News',
    pathCount: 5,
  },
  {
    id: 'niche9',
    title: 'Medical content writing',
    description: 'Our writers are medical professionals who can deliver concise, factual, and researcher-backed medical content.',
    iconClassName: 'icon-Doctor',
    pathCount: 2,
  },
  {
    id: 'niche10',
    title: 'E-commerce content writing',
    description: 'Exceptional e-commerce content writing services tailored to enhance your brand identity.',
    iconClassName: 'icon-Cart',
    pathCount: 5,
  },
];