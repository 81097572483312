import React from 'react';
import {
  Carousel,
  CarouselCard,
  CarouselNavContainer,
  CarouselSlider,
  makeStyles, mergeClasses,
  tokens,
} from '@fluentui/react-components';
import { data } from '../data';

const useClasses = makeStyles({
  slider: {
    gap: '2rem',
    '@media (max-width: 1439px)': {
      paddingInline: 'clamp(15px, 3vw, 40px)',
    },
  },
  wrapperCarousel: {
    display: 'none',
    '@media (max-width: 1439px)': {
      display: 'block',
    },
  },
  wrapperNavContainer: {
    gap: '2rem',
    marginTop: '1.30rem',
    justifyContent: 'end',
    '& button': {
      maxWidth: '2.5rem',
      '& span': {
        width: '24px',
        height: '24px',
        fontSize: '24px',
      },
      '&:hover': {
        backgroundColor: tokens.colorSubtleBackgroundHover,
        borderRadius: tokens.borderRadiusCircular,
        '& svg': {
          color: tokens.colorNeutralForeground1,
        },
      },
    },
  },
  wireframe: {
    display: 'flex',
    flexDirection: 'column',
    height: '22rem',
    backgroundColor: tokens.colorNeutralBackground1,
    position: 'relative',
    borderRadius: '5px',
    '@media (max-width: 1439px)': {
      height: 'auto',
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  wireframeSize: {
    minWidth: '100px',
    maxWidth: '32rem',
    '@media (max-width: 767px)': {
      maxWidth: 'auto',
    },
  },
  wrapperColumn: {
    backgroundColor: tokens.colorNeutralBackground1,
    borderRadius: tokens.borderRadiusLarge,
    padding: '32px',
    height: '100%',
    '@media (max-width: 767px)': {
      padding: '1rem',
    },
    '& h3': {
      color: tokens.colorNeutralForeground1,
      marginBottom: '1rem',
      fontWeight: tokens.fontWeightSemibold,
      fontSize: tokens.fontSizeHero800,
      lineHeight: tokens.lineHeightHero800,
      '@media (max-width: 1439px)': {
        fontSize: tokens.fontSizeBase500,
        lineHeight: tokens.lineHeightBase500,
      },

      '@media (max-width: 767px)': {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
      },
    },
    '& p': {
      color: tokens.colorNeutralForeground2,
      fontSize: tokens.fontSizeBase400,
      lineHeight: tokens.lineHeightBase400,
      fontWeight: tokens.fontWeightRegular,
      '@media (max-width: 767px)': {
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
      },
    },
  },
  step: {
    display: 'inline-block',
    marginBottom: '0.5rem',
    color: tokens.colorNeutralForeground1,
    fontSize: tokens.fontSizeBase200,
    lineHeight: tokens.lineHeightBase200,
  },
});

const getAnnouncement = (index: number, totalSlides: number) => {
  return `Carousel slide ${index + 1} of ${totalSlides}`;
};

export default function BlogWritingCarousel() {
  const styles = useClasses();
  return (
    <Carousel
      draggable
      circular={false}
      groupSize={1}
      className={styles.wrapperCarousel}
      announcement={getAnnouncement}
    >
      <CarouselSlider className={styles.slider}>
        {data.map(data => <CarouselCard autoSize key={data.id}>
          <div className={mergeClasses(styles.wireframe, styles.wireframeSize)}>
            <div className={styles.wrapperColumn}>
              <span className={styles.step}>{data.step}</span>
              <h3>{data.title}</h3>
              <p>
                {data.description}
              </p>
            </div>
          </div>
        </CarouselCard>)}
      </CarouselSlider>

      <div className="container">
        <CarouselNavContainer
          layout="inline"
          next={{
            'aria-label': 'go to next',
          }}
          prev={{
            'aria-label': 'go to prev',
          }}
          className={styles.wrapperNavContainer}
        />
      </div>
    </Carousel>
  );
}