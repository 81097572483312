export interface TagData {
  id: string;
  text: string;
  type: 'content' | 'api';
}

export const TAGS_DATA: TagData[] = [
  'Article Writing',
  'Blog Posts',
  'Content Strategy',
  'Copywriting',
  'Ghostwriting',
  'Press Releases',
  'Product Descriptions',
  'SEO content',
  'Website Content',
  'SaaS content writing',
  'Technical content writing',
  'Crypto content writing',
  'iGaming & Casino content writing',
  'B2B content writing',
  'Creative content writing',
  'Local content writing',
  'News and analytics content writing',
  'Medical content writing',
  'Ecommerce content writing',
].map((text) => ({
  id: text.toLowerCase().replace(/[&\s]+/g, '-'),
  text,
  type: 'content' as const,
}));

export const API_TAGS_DATA: TagData[] = [
  'World News API',
  'Entertainment news API',
  'Celebrities Gossips API',
  'Science & Technology News API',
  'Financial News API',
  'Local News api',
  'Crypto News API',
  'Sports News API',
  'Stock News API',
  'Forex News API',
  'Football News API',
  'Business News API',
  'Breaking News API',
  'Historical News API',
  'Agriculture news API',
  'Google News API',
  'Bing News API',
  'Yahoo News API',
  'Bloomberg News API',
  'Cnn News API',
  'Reuters News API',
  'Fox News API',
  'AP News API',
  'BBC News API',
  'Alpaca News API',
  'Hacker News API',
].map((text) => ({
  id: text.toLowerCase().replace(/[&\s]+/g, '-'),
  text,
  type: 'api' as const,
}));
