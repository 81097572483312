import { additionalOptions, niches, PriceModifierType, wordCountPricing } from './data';

export const pricingTableData = {
  pricing: {
    title: 'Pricing',
    columns: [
      { key: 'words', label: 'Words' },
      { key: 'price', label: 'Price' },
    ],
    data: wordCountPricing.map(item => ({
      words: item.wordRange,
      price: item.pricePerWord === 'custom' ? 'Leave a request to us by Email' : `€ ${item.pricePerWord} per word`,
    })),
  },
  additionalOptions: {
    title: 'Additional Options',
    subText: '*can be included for free into an existing order.',
    columns: [
      { key: 'optionType', label: 'Option Types' },
      { key: 'price', label: 'Price' },
    ],
    data: additionalOptions.map(option => ({
      optionType: option.name,
      price: option.priceModifier.type === PriceModifierType.PERCENTAGE
        ? `+${option.priceModifier.value}% to the base cost`
        : option.priceModifier.type === PriceModifierType.FIXED
          ? `+$${option.priceModifier.value} per unit`
          : option.priceModifier.type === PriceModifierType.CUSTOM && option.priceModifier.value === 'free'
            ? 'Free on request*'
            : option.priceModifier.value === 'translation'
              ? 'Translation fee depends on language'
              : 'Contact us for individual pricing',
    })),
  },
  nicheContent: {
    title: 'Niche Content Writing',
    subText: '*can be included for free into an existing order.',
    columns: [
      { key: 'nicheType', label: 'Niche Types' },
      { key: 'price', label: 'Price' },
    ],
    data: niches.map(niche => ({
      nicheType: niche.name,
      price: niche.priceModifier.type === PriceModifierType.PERCENTAGE
        ? `+${niche.priceModifier.value}%`
        : 'Free on request*',
    })),
  },
};