import {
  Button,
  Image,
  Input,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components';
import { Link } from 'react-router-dom';

import logoDark from '../../../assets/images/logo/bigLogoDark.svg';
import logoLight from '../../../assets/images/logo/bigLogoLight.svg';
import { Row, Col } from '../../shared/Grid';
import { useTheme } from '../../../hooks/useTheme';

const useClasses = makeStyles({
  footerWrapper: {
    backgroundColor: tokens.colorNeutralBackground4,
    padding: '4rem 0px',
    '@media (max-width: 1439px)': {
      padding: '3rem 0px',
    },
    '@media (max-width: 767px)': {
      padding: '2rem 0px',
    },
  },
  wrapperBottomText: {
    textAlign: 'center',
    padding: '0.62rem 0',
    backgroundColor: tokens.colorNeutralBackground4,
    fontSize: tokens.fontSizeBase400,
    lineHeight: tokens.lineHeightBase400,
    color: tokens.colorNeutralForeground2,
  },
  wrapperImage: {
    height: '100%',
    border: `${tokens.strokeWidthThick} solid ${tokens.colorNeutralStroke1}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 1439px)': {
      border: `${tokens.strokeWidthThick} solid transparent`,
      padding: '2.92rem 0px 5.92rem 0px',
      borderBottom: `${tokens.strokeWidthThick} solid ${tokens.colorNeutralStroke1}`,
    },
  },
  columnHeaderText: {
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase400,
    fontSize: tokens.fontSizeBase400,
    color: tokens.colorNeutralForeground2,
    marginBottom: '1rem',
  },
  columnHeaderTextLast: {
    '@media (max-width: 767px)': {
      textAlign: 'center',
    },
  },
  columnItemText: {
    fontWeight: tokens.fontWeightRegular,
    lineHeight: tokens.lineHeightBase400,
    fontSize: tokens.fontSizeBase400,
    color: tokens.colorNeutralForeground2,
    marginBottom: '0.5rem',
  },
  wrapperColumnWithContent: {
    padding: '2.41rem 0px',
    paddingLeft: '104px',
    '@media (max-width: 1439px)': {
      paddingLeft: 0,
    },
    '@media (max-width: 767px)': {
      padding: '1rem 0px 0px 0px',
    },
  },
  wrapperColumnContact: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      order: '-1',
    },
  },
  wrapperInput: {
    marginBottom: '1rem',
  },
  input: {
    width: '100%',
    border: `1px solid ${tokens.colorTransparentStroke}`,
    paddingRight: 0,
    borderRadius: tokens.borderRadiusCircular,
    '::after': {
      display: 'none',
    },
    ':active, :hover': {
      borderBottomColor: 'transparent',
      borderTopColor: 'transparent',
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
    },
    ':focus-within': {
      borderBottomColor: 'transparent !important',
      borderTopColor: 'transparent !important',
      borderLeftColor: 'transparent !important',
      borderRightColor: 'transparent !important',
    },
  },
  contentAfter: {
    backgroundColor: tokens.colorNeutralForeground1,
    color: tokens.colorNeutralForegroundInverted,
    fontWeight: tokens.fontWeightSemibold,
    fontSize: tokens.fontSizeBase300,
    lineHeight: tokens.lineHeightBase300,
    borderRadius: tokens.borderRadiusCircular,
  },
  networks: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& span': {
      color: tokens.colorNeutralForeground2,
      fontSize: tokens.fontSizeBase400,
      lineHeight: tokens.lineHeightBase400,
      fontWeight: tokens.fontWeightSemibold,
      '@media (max-width: 767px)': {
        marginBottom: '16px',
      },
    },

    '@media (max-width: 767px)': {
      marginBottom: '16px',
      flexDirection: 'column',
      order: '-1',
    },
  },
  links: {
    '& a': {
      marginRight: '2rem',
      fontSize: '24px',
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
});

export default function AppFooter() {
  const { isDarkTheme } = useTheme();
  const fluentStyles = useClasses();

  return (
    <>
      <footer className={fluentStyles.footerWrapper}>
        <div className="container">
          <Row
            gap={{ mobile: 0, tablet: 0, desktop: 0, large: 32, default: 32 }}
          >
            <Col
              span={{
                mobile: 12,
                tablet: 12,
                desktop: 3,
                large: 3,
                default: 12,
              }}
            >
              <div className={fluentStyles.wrapperImage}>
                <Image
                  src={isDarkTheme ? logoDark : logoLight}
                  alt="Contenton Logo"
                  role="presentation"
                />
              </div>
            </Col>

            <Col
              span={{
                mobile: 12,
                tablet: 12,
                desktop: 9,
                large: 9,
                default: 12,
              }}
            >
              <Row className={fluentStyles.wrapperColumnWithContent}>
                <Col
                  span={{
                    mobile: 6,
                    tablet: 3,
                    desktop: 3,
                    large: 3,
                    default: 3,
                  }}
                >
                  <h5 className={fluentStyles.columnHeaderText}>Quick links</h5>
                  <div className={fluentStyles.columnItemText}>
                    <Link to="/">Content writing services</Link>
                  </div>
                  <div className={fluentStyles.columnItemText}>
                    <Link to="/">Solutions</Link>
                  </div>
                  <div className={fluentStyles.columnItemText}>
                    <Link to="/">Publishing</Link>
                  </div>
                  <div className={fluentStyles.columnItemText}>
                    <Link to="/">Pricing</Link>
                  </div>
                  <div className={fluentStyles.columnItemText}>
                    <Link to="/">Blog</Link>
                  </div>
                </Col>
                <Col
                  span={{
                    mobile: 6,
                    tablet: 3,
                    desktop: 3,
                    large: 3,
                    default: 3,
                  }}
                >
                  <h5 className={fluentStyles.columnHeaderText}>Resources</h5>
                  <div className={fluentStyles.columnItemText}>
                    <Link to="/">Terms of use</Link>
                  </div>
                  <div className={fluentStyles.columnItemText}>
                    <Link to="/">Privacy Policy</Link>
                  </div>
                  <div className={fluentStyles.columnItemText}>
                    <Link to="/">Contacts</Link>
                  </div>
                  <div className={fluentStyles.columnItemText}>
                    <Link to="/">FAQ</Link>
                  </div>
                </Col>
                <Col
                  span={{
                    mobile: 12,
                    tablet: 6,
                    desktop: 6,
                    large: 6,
                    default: 6,
                  }}
                  className={fluentStyles.wrapperColumnContact}
                >
                  <div>
                    <h5
                      className={mergeClasses(
                        fluentStyles.columnHeaderText,
                        fluentStyles.columnHeaderTextLast,
                      )}
                    >
                      Subscribe to our news
                    </h5>
                    <div className={fluentStyles.wrapperInput}>
                      <Input
                        className={fluentStyles.input}
                        placeholder="Enter your email"
                        contentAfter={
                          <Button className={fluentStyles.contentAfter}>
                            Send
                          </Button>
                        }
                      />
                    </div>

                    <div>
                      <span>
                        Fill out the form below and stay in touch with the
                        latest news and ideas to help your business reach new
                        heights!
                      </span>
                    </div>
                  </div>

                  <div className={fluentStyles.networks}>
                    <span>Follow us</span>
                    <div className={fluentStyles.links}>
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                      <a href="#" className="icon-LinkedIn" />
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                      <a href="#" className="icon-Instagram" />
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                      <a href="#" className="icon-Facebook" />
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                      <a href="#" className="icon-X" />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </footer>
      <div className={fluentStyles.wrapperBottomText}>
        <span>© 2024 Contenton. All rights reserved.</span>
      </div>
    </>
  );
}
