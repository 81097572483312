interface FeatureItem {
  id: string;
  title: string;
  iconClassName: string;
  pathCount: number;
}

export const featuresData: FeatureItem[] = [{
  id: '1',
  title: 'Seasoned niche writing journalists.',
  iconClassName: 'icon-TargetArrow',
  pathCount: 2,
},
  {
    id: '2',
    title: 'Custom writing solutions guided by brand voice.',
    iconClassName: 'icon-PersonStarburst',
    pathCount: 2,
  },
  {
    id: '3',
    title: '100% human, natural, and SEO optimized.',
    iconClassName: 'icon-Shield',
    pathCount: 2,
  },
  {
    id: '4',
    title: 'At a fraction of your budget.',
    iconClassName: 'icon-MoneySettings',
    pathCount: 2,
  },
  {
    id: '5',
    title: 'Your business gets viewer, increases sales and bottom lines.',
    iconClassName: 'icon-ArrowTrendingLines',
    pathCount: 2,

  },
];