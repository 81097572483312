import { useCallback } from 'react';
import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  Label,
  Button,
  Body1,
} from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import { Formik, Form, FormikProps } from 'formik';

import {
  additionalOptions,
  niches,
  PriceModifierType,
} from '../../../pages/ContentWritingServicesPage/PriceInfoSection/data';
import {
  NICHES_MAP,
  OPTIONS_MAP,
  WORD_COUNT_MAP,
} from '../../../pages/ContentWritingServicesPage/PriceInfoSection/calculation/pricingMaps';
import FormikCombobox from '../../formik/FormikCombobox';
import FormikNumberInput from '../../formik/FormikNumberInput';
import FormikCheckboxGroup from '../../formik/FormikCheckboxGroup';
import { Row, Col } from '../../shared/Grid';
import { useClassNames } from '../../../styles/global.fluentui.styles';

import useClasses from './styles';

interface PricingFormInputs {
  selectedNicheId: string;
  wordCount: number;
  selectedOptionIds: string[];
}

type PricingCalculatorModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  defaultNicheId?: string;
};

export default function PricingCalculatorModal(props: PricingCalculatorModalProps) {
  const { isOpen, onDismiss, defaultNicheId } = props;
  const styles = useClasses();
  const globalStyles = useClassNames();

  const calculateBasePrice = useCallback((wordCount: number): number => {
    const pricing = Array.from(WORD_COUNT_MAP.values()).find(
      p => wordCount >= p.minWords && wordCount < p.maxWords,
    );

    if (!pricing || pricing.pricePerWord === 'custom') return 0;
    return wordCount * parseFloat(pricing.pricePerWord);
  }, []);

  const calculateTotalPrice = useCallback((values: PricingFormInputs): string => {
    const basePrice = calculateBasePrice(values.wordCount);
    if (basePrice === 0) return 'Contact us for pricing';

    let totalPrice = basePrice;

    const nicheModifier = NICHES_MAP.get(values.selectedNicheId);
    if (nicheModifier?.type === PriceModifierType.PERCENTAGE) {
      totalPrice *= (1 + (nicheModifier.value as number) / 100);
    }
    
    values.selectedOptionIds?.forEach(optionId => {
      const option = OPTIONS_MAP.get(optionId);

      if (option?.type === PriceModifierType.PERCENTAGE) {
        totalPrice *= (1 + (option.value as number) / 100);
      } else if (option?.type === PriceModifierType.FIXED) {
        totalPrice += option.value as number;
      }
    });

    return `€${totalPrice.toFixed(2)}`;
  }, [calculateBasePrice]);

  return (
    <Dialog open={isOpen} modalType="modal">
      <DialogSurface>
        <DialogBody>
          <DialogTitle className={styles.wrapperHeader}>
            Pricing Calculator
            <Button
              appearance="subtle"
              aria-label="close"
              icon={<DismissRegular />}
              onClick={onDismiss}
            />
          </DialogTitle>
          <DialogContent>
            <Formik<PricingFormInputs>
              initialValues={{
                selectedNicheId: defaultNicheId || '',
                wordCount: 0,
                selectedOptionIds: [],
              }}
              onSubmit={onDismiss}
            >
              {(formik: FormikProps<PricingFormInputs>) => (
                <Form className={styles.wrapperForm}>
                  <Row gap={16}>
                    <Col>
                      <FormikCombobox
                        name="selectedNicheId"
                        label="Select niche"
                        options={niches}
                        placeholder="Select your niche"
                      />
                    </Col>
                    <Col>
                      <FormikNumberInput
                        name="wordCount"
                        label="Count of words"
                        min={0}
                        max={999999999}
                      />
                    </Col>
                    <Col>
                      <FormikCheckboxGroup
                        name="selectedOptionIds"
                        label="Additional options"
                        options={additionalOptions.slice(1)}
                        getOptionLabel={(option) => option.name}
                      />
                    </Col>
                    <Col>
                      <div className={styles.wrapperFooter}>
                        <div className={styles.total}>
                          <Label>Total:</Label>
                        </div>
                        <div className={styles.dottedLine} />
                        <div className={styles.price}>
                          <Body1>{calculateTotalPrice(formik.values)}</Body1>
                        </div>
                      </div>
                      <div className={styles.wrapperBtn}>
                        <Button
                          className={globalStyles.smallButtonWrapper}
                        >
                          Contact us
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}