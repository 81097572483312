import { questions, QuestionModel } from './data';
import { useClassNames } from '../../../styles/global.fluentui.styles';
import { Row, Col } from '../../../components/shared/Grid';
import AccordionText from '../../../components/shared/AccordionText/AccordionText';

export default function QuestionsSection() {
  const globalStyles = useClassNames();

  return (
    <section className={globalStyles.wrapperSection}>
      <div className="container">
        <div className={globalStyles.wrapperHeaderSection}>
          <h1 className={globalStyles.mainHeader}>Frequently asked questions</h1>
        </div>
        <Row>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 12 }}
            offset={{
              mobile: 0,
              tablet: 0,
              desktop: 2,
              large: 2,
              default: 0,
            }}
          >
            <AccordionText<QuestionModel> data={questions} />
          </Col>
        </Row>
      </div>
    </section>
  );
}
