import ContentWithImageSection from '../../../components/shared/ContentWithImageSection';

import image from '../../../assets/images/contentWritingServices/marketing/image.webp';

export default function ContentStrategyServicesSection() {
  return (
    <>
      <ContentWithImageSection
        headerTitle="Content Marketing & Content Strategy Services"
        headerDescription="Do you feel stuck in your content marketing strategies? Our content marketing and content strategy services will give you fresh ideas. We aim to grow the marketing return on investing for your content writing spending."
        mainTitle="Get content marketing and content strategy services to grow ROI"
        mainDescription="Our content marketing strategists will assist you in identifying the most suitable content channels for your business, setting goals, and settling on the most effective content types for your business."
        linkPath="/content-writing-services"
        imageSrc={image}
      />
    </>
  );
}