import { FC } from 'react';
import { useStyles } from './styles';

interface BurgerButtonProps {
  isOpen: boolean;
  onClick: () => void;
}

export const BurgerButton: FC<BurgerButtonProps> = ({ isOpen, onClick }) => {
  const classes = useStyles();

  return (
    <button
      className={`${classes.burgerBtn} ${isOpen ? classes.active : ''}`}
      onClick={onClick}
      aria-label="Toggle menu"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};
