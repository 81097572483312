import { ReactNode } from 'react';

import AppHeader from '../AppHeader';
import AppFooter from '../AppFooter';

type MainLayoutProps = {
  children: ReactNode;
};

export default function MainLayout(props: MainLayoutProps) {
  const { children } = props;
  return (
    <>
      <AppHeader />
      {children}
      <AppFooter />
    </>
  );
}
