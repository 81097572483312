import { FC } from 'react';
import { Checkbox, Field, useId } from '@fluentui/react-components';
import { useField } from 'formik';

import useClasses from './styles';

interface CheckboxOption {
  id: string | number;
  name: string;
  description?: string;
  disabled?: boolean;
}

interface FormikCheckboxGroupProps {
  name: string;
  label: string;
  options: CheckboxOption[];
  required?: boolean;
  disabled?: boolean;
  className?: string;
  description?: string;
  onChange?: (selectedIds: (string | number)[]) => void;
  getOptionLabel?: (option: CheckboxOption) => string;
}

const FormikCheckboxGroup: FC<FormikCheckboxGroupProps> = (props: FormikCheckboxGroupProps) => {
  const {
    name,
    label,
    options,
    required = false,
    disabled = false,
    className,
    description,
    onChange,
    getOptionLabel = (option) => option.name,
  } = props;
  const [field, meta, helpers] = useField<(string | number)[]>(name);
  const id = useId();
  const styles = useClasses();

  const validationState = meta.touched && meta.error ? 'error' : undefined;
  const validationMessage = meta.touched ? meta.error : undefined;

  const handleChange = (optionId: string | number) => {
    const newValue = field.value.includes(optionId)
      ? field.value.filter(id => id !== optionId)
      : [...field.value, optionId];

    helpers.setValue(newValue);
    helpers.setTouched(true);
    onChange?.(newValue);
  };

  return (
    <Field
      label={label}
      required={required}
      validationState={validationState}
      validationMessage={validationMessage}
      hint={description}
      className={className}
    >
      <div className={styles.checkboxGrid}>
        {options.map((option) => (
          <Checkbox
            key={option.id}
            id={`${id}-${option.id}`}
            label={getOptionLabel(option)}
            checked={field.value.includes(option.id)}
            onChange={() => handleChange(option.id)}
            disabled={disabled || option.disabled}
            className={styles.checkbox}
          />
        ))}
      </div>
    </Field>
  );
};

export default FormikCheckboxGroup;