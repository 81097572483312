import { makeStyles, tokens } from '@fluentui/react-components';

const useClasses = makeStyles({
  wrapper: {
    // paddingTop: '2rem',
    '& a': {
      '&:hover': {
        backgroundColor: 'transparent',
        color: tokens.colorNeutralForegroundStaticInverted,
      },
      '&:active': {
        backgroundColor: 'transparent',
        color: tokens.colorNeutralForegroundStaticInverted,
      },
      '&:hover:active': {
        backgroundColor: 'transparent',
        color: tokens.colorNeutralForegroundStaticInverted,
      },
    },
    '& i': {
      fontSize: '24px',
    },
  },
  label: {
    color: tokens.colorNeutralForegroundStaticInverted,
    fontSize: tokens.fontSizeBase400,
    lineHeight: tokens.lineHeightBase400,
    fontWeight: tokens.fontWeightRegular,
    
    '@media (max-width: 767px)': {
      fontSize: tokens.fontSizeBase200,
      lineHeight: tokens.lineHeightBase200,
    },
  },
  dot: {
    color: tokens.colorNeutralForegroundStaticInverted,
  },
});

export default useClasses;