import tab1Img from '../../../assets/images/home/contentAutomation/tab1Img.webp';

export interface ContentAutomationSectionData {
  id: string;
  header: string;
  details: string;
  imageSrc: string;
}

export const data: ContentAutomationSectionData[] = [
  {
    id: '1-1-1',
    header: 'Autoblogging',
    details:
      'Our autoblogging plugin lets you publish large volumes of content easily. You can schedule and automate content generation and publishing. ',
    imageSrc: tab1Img,
  },
  {
    id: '2-2-2',
    header: 'Content Automation',
    details:
      'Our autoblogging plugin lets you publish large volumes of content easily.',
    imageSrc: tab1Img,
  },
  {
    id: '3-3-3',
    header: 'Unique tone of voice',
    details:
      'You can schedule and automate content generation and publishing. ',
    imageSrc: tab1Img,
  },
];
