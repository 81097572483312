import { useClassNames } from '../../../styles/global.fluentui.styles';
import AccordionWithPreview from '../../../components/shared/AccordionWithPreview';
import { writingServiceSlides, WritingServiceSlide } from './data';

export default function ContentIsKingSection() {
  const globalStyles = useClassNames();

  return (
    <section className={globalStyles.wrapperSection}>
      <div className="container">
        <div className={globalStyles.wrapperHeaderSection}>
          <h1 className={globalStyles.mainHeader}>Content is the Key</h1>
          <h2 className={globalStyles.mainDescription}>Let's open the door for your traffic growth with it.</h2>
        </div>
        <AccordionWithPreview<WritingServiceSlide> data={writingServiceSlides} height="695px" minHeight="695px" />
      </div>
    </section>
  );
}