import { makeStyles, tokens } from '@fluentui/react-components';

import { Row, Col } from '../../../components/shared/Grid';
import ContentAutomationTabList from './ContentAutomationTabList';
import { useClassNames } from '../../../styles/global.fluentui.styles';

const useClasses = makeStyles({
  wrapper: {
    backgroundColor: tokens.colorNeutralBackground4,
    padding: '4rem 0px',
    '@media (max-width: 1439px)': {
      padding: '3rem 0px',
    },
    '@media (max-width: 767px)': {
      padding: '2rem 0px',
    },
  },
});

export default function ContentAutomationSection() {
  const fluentStyles = useClasses();
  const globalStyles = useClassNames();

  return (
    <section className={fluentStyles.wrapper}>
      <div className="container">
        <Row>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 12 }}
            offset={{
              mobile: 0,
              tablet: 0,
              desktop: 2,
              large: 2,
              default: 0,
            }}
          >
            <div className={globalStyles.wrapperHeaderSection}>
              <h1 className={globalStyles.mainHeader}>
                Content Automation and Autoblogging
              </h1>
              <h2 className={globalStyles.mainDescription}>
                How much more can you make with autoblogging? To generate
                human-like AI content at scale, use our automated blog content
                generator. Our AI Media Monitoring Tool incorporates insights from
                your sources and the trending topics into your autoblogging
                WordPress.
              </h2>
            </div>
          </Col>
        </Row>
      </div>
      <ContentAutomationTabList />
    </section>
  );
}
