import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { Row, Col } from '../../../components/shared/Grid';
import ContentCarousel from './ContentCarousel';
import { useClassNames } from '../../../styles/global.fluentui.styles';

const useClasses = makeStyles({
  sectionWrapper: {
    backgroundColor: tokens.colorNeutralBackground4,
    padding: '4rem 0px',
    '@media (max-width: 1439px)': {
      padding: '3rem 0px',
    },
    '@media (max-width: 767px)': {
      padding: '2rem 0px',
    },
  },
  headerWrapper: {
    marginBottom: '2rem',
    '@media (max-width: 1439px)': {
      marginBottom: '3rem',
    },
    '@media (max-width: 767px)': {
      marginBottom: '2rem',
    },
  },
});

export default function ContentWritingSection() {
  const fluentStyles = useClasses();
  const globalStyles = useClassNames();

  return (
    <section className={fluentStyles.sectionWrapper}>
      <div className={mergeClasses('container', fluentStyles.headerWrapper)}>
        <Row>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 12 }}
            offset={{
              mobile: 0,
              tablet: 0,
              desktop: 2,
              large: 2,
              default: 0,
            }}
          >
            <div className={globalStyles.wrapperHeaderSection}>
              <h1 className={globalStyles.mainHeader}>Content Writing</h1>
              <h2 className={globalStyles.mainDescription}>
                What are your most pressing content writing needs? You name it. We
                create it. We write unique yet scalable digital content based on
                research and data. Brands that outsource content writing to
                Contenton increase their web traffic within a few days.
                <br /> Here's what we do:
              </h2>
            </div>
          </Col>
        </Row>
      </div>

      <ContentCarousel />
    </section>
  );
}
