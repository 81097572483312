import { Button, mergeClasses } from '@fluentui/react-components';
import Breadcrumbs from '../../../components/shared/Breadcrumbs';
import { Col, Row } from '../../../components/shared/Grid';
import * as React from 'react';

import useClasses from './styles';

export default function MainBanner() {
  const styles = useClasses();

  return (
    <section className={styles.backgroundImage}>
      <div className={mergeClasses('container', styles.fullHeight)}>
        <Breadcrumbs items={[
          {
            path: '/',
            label: <i className="icon icon-Home" />,
          },
          {
            path: '/content-writing-services',
            label: 'Content writing services',
          },
          {
            path: '/blog',
            label: 'Blog post writing services ',
          },
        ]} />
        <Row className={styles.fullHeight}>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 8 }}
            offset={{ mobile: 0, tablet: 0, desktop: 2, default: 2 }}
            className={styles.wrapperColumn}>
            <h1 className={styles.mainText}>Blog Writing Services</h1>
            <h2 className={styles.subText}>
              Blog writing is the number one tool to inform, educate, and engage with your audience on the topics they
              seek - with all the modern AI tools and video blogging, most people still prefer to read when they want
              fast, precise and detailed answers to their questions. With Contenton blog writing services, you will no
              longer have strain to send the message across. At our content writing agency, we know how to write
              compelling articles tailored to your audience’s search request.
            </h2>
            <div>
              <Button
                className={styles.buttonWrapper}
                size="small"
                shape="square"
                appearance="primary"
              >
                Calculate
              </Button>
            </div>
          </Col>

        </Row>

      </div>
    </section>
  );
}