import { makeStyles, tokens } from '@fluentui/react-components';

const useClasses = makeStyles({
  slider: {
    gap: '2rem',
    '@media (max-width: 1439px)': {
      paddingInline: 'clamp(15px, 3vw, 40px)',
    },
  },
  wrapperNichesCarousel: {
    display: 'none',
    '@media (max-width: 1439px)': {
      display: 'block',
    },
  },
  wrapperNavContainer: {
    gap: '2rem',
    marginTop: '1.30rem',
    justifyContent: 'end',
    '& button': {
      maxWidth: '2.5rem',
      '& span': {
        width: '24px',
        height: '24px',
        fontSize: '24px',
      },
      '&:hover': {
        backgroundColor: tokens.colorSubtleBackgroundHover,
        borderRadius: tokens.borderRadiusCircular,
        '& svg': {
          color: tokens.colorNeutralForeground1,
        },
      },
    },
  },
});

export default useClasses;
