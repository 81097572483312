import { Table, TableBody, TableCell, TableHeader, TableRow } from '@fluentui/react-components';

import useClasses from './styles';

type TableColumn = {
  key: string;
  label: string;
};

type SharedTableProps = {
  title: string;
  columns: TableColumn[];
  data: Record<string, string | number>[];
  subText?: string;
};

export default function PricingDataTable(props: SharedTableProps) {
  const { title, columns, data, subText } = props;
  const styles = useClasses();

  return (
    <div className={styles.tableWrapper}>
      <h2 className={styles.tableHeader}>{title}</h2>
      <div className={styles.tableContainer}>
        <Table>
          <TableHeader>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.key}>
                  <span className={styles.columnTitle}>
                    {column.label}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                {columns.map((column) => (
                  <TableCell key={column.key}>{row[column.key]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {subText && <div className={styles.wrapperSubText}><span>{subText}</span></div>}
    </div>
  );
};