import { useClassNames } from '../../../styles/global.fluentui.styles';
import { Row, Col } from '../../../components/shared/Grid';

import useClasses from './styles';

export default function BlogServicesSection() {
  const styles = useClasses();
  const globalStyles = useClassNames();

  return (
    <section className={globalStyles.wrapperSection}>
      <div className="container">
        <Row>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 12 }}
            offset={{
              mobile: 0,
              tablet: 0,
              desktop: 2,
              large: 2,
              default: 0,
            }}
          >
            <div className={globalStyles.wrapperHeaderSection}>
              <h1 className={globalStyles.mainHeader}>What Blog Writing Services Does Contenton Provide?</h1>
            </div>
          </Col>
        </Row>
        <Row gap={32}>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 4, large: 4, default: 4 }}
          >
            <div className={styles.wrapperColumn}>
              <i className="icon icon-Document" />
              <h3>Informative Articles</h3>
              <p>
                We write informative blog posts based on topics relevant to your industry and incorporate organic
                keywords. These posts provide your audience with advice and insights on various issues that may affect
                their purchase decisions or satisfaction with your products.
              </p>
            </div>
          </Col>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 4, large: 4, default: 4 }}
          >
            <div className={styles.wrapperColumn}>
              <i className="icon icon-News1" />
              <h3>Industry News</h3>
              <p>
                We provide your audience with timely updates on the latest events happening in your industry. Our team
                also analyzes your industry’s latest trends and developments to inform your audience. Our blog writing
                service makes you a solid resource on matters regarding your niche.
              </p>
            </div>
          </Col>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 4, large: 4, default: 4 }}
          >
            <div className={styles.wrapperColumn}>
              <i className="icon icon-BookSearch" />
              <h3>How-To Guides</h3>
              <p>
                We can help you educate your audience on how to use your products’ full potential. They could be
                installation, troubleshooting, and care guides or instructions. How-to guides ensure that your customers
                best use your products and services to meet their needs. This way, our blog writing increases the
                chances of return purchases.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}