import { makeStyles, tokens } from '@fluentui/react-components';
import { Row, Col } from '../../../components/shared/Grid';
import { BuilderFeature, builderFeatures } from './data';
import AccordionWithPreview from '../../../components/shared/AccordionWithPreview';
import { useClassNames } from '../../../styles/global.fluentui.styles';

const useClasses = makeStyles({
  wrapper: {
    backgroundColor: tokens.colorNeutralBackground4,
    padding: '4rem 0px',
    '@media (max-width: 1439px)': {
      padding: '3rem 0px',
    },
    '@media (max-width: 767px)': {
      padding: '2rem 0px',
    },
  },
});

export default function MultiWebsiteBuilderSection() {
  const fluentStyles = useClasses();
  const globalStyles = useClassNames();

  return (
    <section className={fluentStyles.wrapper}>
      <div className="container">
        <Row>
          <Col
            span={{ mobile: 12, tablet: 12, desktop: 8, large: 8, default: 12 }}
            offset={{
              mobile: 0,
              tablet: 0,
              desktop: 2,
              large: 2,
              default: 0,
            }}
          >
            <div className={globalStyles.wrapperHeaderSection}>
              <h1 className={globalStyles.mainHeader}>
                Multi Website Builder with AI integration
              </h1>
              <h2 className={globalStyles.mainDescription}>
                Do you want to build a blog or website in minutes without any
                coding? Contenton creates your new WordPress blog or website in
                under 10 minutes using our no-code AI website builder. Our AI
                website maker will deploy your website within a few clicks when
                you sign up.
              </h2>
            </div>
          </Col>
        </Row>
        <AccordionWithPreview<BuilderFeature> data={builderFeatures} />
      </div>
    </section>
  );
}
