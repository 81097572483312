import { createContext, ReactNode } from 'react';
import {
  FluentProvider,
  webDarkTheme,
  webLightTheme,
  Theme as FluentTheme,
} from '@fluentui/react-components';

import { useSystemTheme } from '../../hooks/useSystemTheme';

interface ThemeContextType {
  isDarkTheme: boolean;
  currentTheme: FluentTheme;
}

export const ThemeContext = createContext<ThemeContextType | undefined>(
  undefined
);

interface ThemeProviderProps {
  children: ReactNode;
}

export default function ThemeProvider(props: ThemeProviderProps) {
  const { children } = props;
  const isDarkTheme = useSystemTheme();
  const currentTheme = isDarkTheme ? webDarkTheme : webLightTheme;

  return (
    <ThemeContext.Provider value={{ isDarkTheme, currentTheme }}>
      <FluentProvider theme={currentTheme}>{children}</FluentProvider>
    </ThemeContext.Provider>
  );
}
