import slide1 from '../../../assets/images/home/contentWriting/slide1.webp';
import slide2 from '../../../assets/images/home/contentWriting/slide2.webp';
import slide3 from '../../../assets/images/home/contentWriting/slide3.webp';
import slide4 from '../../../assets/images/home/contentWriting/slide4.webp';
import slide5 from '../../../assets/images/home/contentWriting/slide5.webp';
import slide6 from '../../../assets/images/home/contentWriting/slide6.webp';
import slide7 from '../../../assets/images/home/contentWriting/slide7.webp';
import slide8 from '../../../assets/images/home/contentWriting/slide8.webp';
import slide9 from '../../../assets/images/home/contentWriting/slide9.webp';

export interface ContentServiceCard {
  id: string;
  title: string;
  description: string;
  imageSrc: string;
  link: string;
}

export const contentServices: ContentServiceCard[] = [
  {
    id: '1',
    title: 'Article writing',
    description:
      'Create evidence-based, informative, long articles on any topic, or subject, that your followers may find worth their interest.',
    imageSrc: slide1,
    link: '',
  },
  {
    id: '2',
    title: 'Blog Posts Writing',
    description:
      'Connect with your audience through regular blog posts that tell your brand story to build your audience\'s confidence in it.',
    imageSrc: slide2,
    link: '',
  },
  {
    id: '3',
    title: 'Content Marketing Strategy',
    description:
      'Revamp your marketing strategies through content that engages your readers by answering their most urgent questions.',
    imageSrc: slide3,
    link: '',
  },
  {
    id: '4',
    title: 'SEO Content Writing',
    description:
      'Rank top in search engine results pages through well-researched unique keyword ideas and data-driven SEO content.',
    imageSrc: slide4,
    link: '',
  },
  {
    id: '5',
    title: 'Copywriting',
    description:
      'Get creative, advert copy that elevates your brand, triggers buyer intent, and drives conversions and sells your brand goods.',
    imageSrc: slide5,
    link: '',
  },
  {
    id: '6',
    title: 'Ghostwriting',
    description:
      'Outsource writing services to professional ghostwriters with expertise in your topic, subject, field, or discipline without any hassle.',

    imageSrc: slide6,
    link: '',
  },
  {
    id: '7',
    title: 'Press Release Writing',
    description:
      'Develop press release templates and be the first to break the news in your field- keep your audience updated and well-informed.',
    imageSrc: slide7,
    link: '',
  },
  {
    id: '8',
    title: 'Product Descriptions Writing',
    description:
      'Write compelling but factually accurate product descriptions for new or existing products and increase discoverability.',
    imageSrc: slide8,
    link: '',
  },
  {
    id: '9',
    title: 'Website Content Writing',
    description:
      'Grow web traffic and develop content that tells your brand story, engages visitors, and compels them to act.',
    imageSrc: slide9,
    link: '',
  },
];
