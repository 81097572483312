import { Carousel, CarouselNavContainer, CarouselSlider } from '@fluentui/react-components';
import { ContentWritingNiche } from '../data';
import NicheCardCarousel from '../NicheCardCarousel';
import React, { MouseEvent } from 'react';

import useClasses from './styles';

const getAnnouncement = (index: number, totalSlides: number) => {
  return `Carousel slide ${index + 1} of ${totalSlides}`;
};

type NicheCarouselProps = {
  data: ContentWritingNiche[],
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;

}
export default function NicheCarousel(props: NicheCarouselProps) {
  const { data, onClick } = props;
  const styles = useClasses();

  return (
    <Carousel
      draggable
      circular={false}
      groupSize={1}
      className={styles.wrapperNichesCarousel}
      announcement={getAnnouncement}
    >
      <CarouselSlider className={styles.slider}>
        {
          data.map(data => <NicheCardCarousel data={data} onClick={onClick} />)
        }
      </CarouselSlider>

      <div className="container">
        <CarouselNavContainer
          layout="inline"
          next={{
            'aria-label': 'go to next',
          }}
          prev={{
            'aria-label': 'go to prev',
          }}
          className={styles.wrapperNavContainer}
        />
      </div>
    </Carousel>
  );
}