import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
} from '@fluentui/react-components';
import { QuestionModel } from '../../../pages/MainPage/QuestionsSection/data';
import { Add20Filled, Subtract20Filled } from '@fluentui/react-icons';
import useClasses from './styles';
import { useCallback, useState } from 'react';

type BaseItem = QuestionModel;

type AccordionTextProps<T extends BaseItem> = {
  data: T[]
};

export default function AccordionText<T extends BaseItem>(props: AccordionTextProps<T>) {
  const { data } = props;
  const [openItem, setOpenItems] = useState<string>();
  const styles = useClasses();

  const handleToggle = useCallback<AccordionToggleEventHandler>((_, data) => {
    setOpenItems(data.value as string);
  }, []);

  return (
    <>
      <Accordion
        className={styles.accordionWrapper}
        onToggle={handleToggle}
        openItems={openItem}
      >
        {data.map((question: QuestionModel) => (
          <AccordionItem
            key={question.id}
            value={question.id}
            className={styles.accordionItem}
          >
            <AccordionHeader
              className={styles.accordionWrapperHeader}
              expandIconPosition="end"
              expandIcon={
                openItem === question.id ? (
                  <Subtract20Filled />
                ) : (
                  <Add20Filled />
                )
              }
            >
              <h1 className={styles.accordionHeader}>
                {question.title}
              </h1>
            </AccordionHeader>
            <AccordionPanel className={styles.accordionPanel}>
              <div className={styles.description}>
                {question.description}
              </div>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
}